.tasting-menu-block {
  margin: 85px auto 140px;

  h2 {
    margin-bottom: 28px;
    font-size: rem(18px);
  }

  .img-wrapper {
    margin-top: -85px;
  }

  &.big {

    .chef.with-img {
      left: 26px;
      right: 16px;

      .chef-name {
        padding-left: 90px;
      }

    }

  }

}

.menu-tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: rem(12px);
  font-weight: 500;
  margin-bottom: 30px;

  button {
    @include reset-btn;

    position: relative;
    display: flex;
    align-items: center;
    margin: 0 5px 10px;
    padding: 0 10px 0 5px;
    border-radius: 30px;
    box-shadow: $box-shadow;

  }

  .icon {
    width: 30px;
    color: inherit;
  }

  .active {
    color: $accent-color;
    border-color: $accent-color;
  }

}

.menu-content {
  padding: 0 10px 20px;

  p {
    margin-bottom: 24px;
    font-size: rem(18px);
    font-style: italic;
    text-align: center;
  }

}

.menu-text {
  display: none;
  max-width: 500px;
  margin: 0 auto;

  &.active {
    display: block;
  }

}

.chef {
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  margin-top: -25px;
  background-image: linear-gradient(to right, $accent-color 60%, transparent 100%);
}

.chef-img-wrapper {
  position: absolute;
  left: -15px;
  top: 50%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  border: 2px solid $accent-color;
  border-radius: 50%;
  overflow: hidden;
}

.chef-name {
  display: block;
  padding: 10px;
  color: $white;
  font-size: rem(18px);
  font-weight: 500;
}

@include small {

  .tasting-menu-block.big .chef.with-img .chef-name {
    padding-left: 130px;
  }

  .chef-img-wrapper {
    width: 126px;
    height: 126px;
    margin-top: -63px;
  }

  .chef-name {
    padding: 10px 20px;
    font-size: rem(22px);
  }

  .menu-tabs {

    button {
      margin: 0 10px 10px;
      padding: 0 20px 0 10px;

    }

    .icon {
      width: 40px;
    }

  }

}

@include large {

  .tasting-menu-block {

    &.big {

      h2 {
        font-size: rem(26px);
      }

      .chef {
        top: 70px;
        left: 0;
        right: 0;
        margin-top: 0;

        &.with-img {
          top: 190px;
          left: 0;
          right: 0;

          .chef-name {
            padding: 20px 10px;
          }

        }

      }

      .chef-name {
        padding: 20px 10px;
        font-size: rem(26px);
        text-align: center;
      }

      .chef-img-wrapper {
        left: 0;
        right: 0;
        top: initial;
        bottom: 100%;
        width: 222px;
        height: 222px;
        margin: 0 auto 10px;
      }

    }

  }

  .chef {
    left: 0;
  }

  .chef-name {
    padding-left: 46px;
  }

}
