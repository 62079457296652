.new-menu-item {
  margin-top: 10px;
  margin-bottom: 30px;
  padding-bottom: 30px;

  .form-group {
    margin-bottom: 0;
    background-color: transparent;
  }

}

.translate-fields {
  display: none;
}

.price-col {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.add-item-btn {
  @include reset-btn;

  position: absolute;
  right: 0;
  bottom: 26px;
  width: 60px;
  padding: 6px;
  background-color: $success-color;
  color: $white;
  font-weight: bold;
}

@include large {

  .translate-fields {
    @include reset-btn;

    display: block;
    position: absolute;
    left: calc(50% - 15px);
    top: 10px;
    width: 30px;
    height: 30px;
    opacity: 0;
    background: url(../../assets/images/translate.png) no-repeat center/100%;
  }

  .new-menu-item .translate-fields {
    left: calc(50% - 65px);
  }

  .editable-text .translate-fields {
    left: calc(50% - 40px);
  }

  .menu-item-group.draggable-inputs .translate-fields {
    left: calc(50% - 27px);
  }

  .grid:hover .translate-fields,
  .show-translate .translate-fields,
  .menu-item-group:hover .translate-fields,
  .editable-text:hover .translate-fields {
    opacity: 1;
  }

}
