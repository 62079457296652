.app-preview-section {
  height: auto;
  margin-bottom: 30vw;

  .app-preview {
    max-width: 548px;
    margin-top: 30px;
    margin-bottom: -25vw;
  }

}

.hero-section-content {
  margin-top: 30px;
}

.host-features {
  margin-bottom: 70px;
}

.host-feature {
  position: relative;
  justify-content: start;
  background-color: $white;

  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: rem(22px);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 60px;
    border-radius: 16px;
    background-color: $accent-color;
  }

}

.download-apps {
  margin-bottom: 20px;

  h2 {
    font-size: rem(20px);
  }

  .mobile-app-stores {
    max-width: 425px;
    margin: 0 auto;
  }

}

.mobile-app-stores {
  text-align: left;
  font-size: rem(12px);
  display: flex;
  justify-content: space-between;

  a {
    display: block;
    text-decoration: none;
  }

  img {
    display: block;
    width: 120px;
    max-width: 100%;
  }

}

.btn-download-host {
  float: left;
  margin-top: 12px;
  margin-left: 10px;
  padding: 6px 10px;
  display: none;
}

@include medium {

  .host-features {
    display: grid;
    column-gap: 40px;
    row-gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .download-apps {
    text-align: center;
  }

  .btn-download-host {
    display: block;
  }

  .qr-code img {
    width: 180px;
  }

}

@include large {

  .app-preview-section {
    height: 500px;
  }

  .hero-section-content {
    margin-top: 0;
  }

  .app-preview-section {
    margin-bottom: 100px;

    .app-preview {
      width: calc(100% - 588px);
      margin-top: 0;
      margin-bottom: -80px;
    }

  }

}

@include larger {

  .btn-download-host {
    margin-top: 25px;
    margin-left: 230px;
  }

}