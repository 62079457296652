.editable-text,
.editable-info-item {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-right: 20px;

  &:not(.editing):hover {

    .edit-btn,
    .delete-btn {
      opacity: 1;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -4px;
      right: -4px;
      top: -4px;
      bottom: -4px;
      border: 2px dashed $accent-color;
    }

  }

  &.editing {
    display: block;
    padding-right: 48px;

    .edit-btn,
    .delete-btn {
      display: none;
    }

  }

  &.deletable {
    padding-right: 48px;

    .delete-btn {
      top: -6px;

      .icon {
        width: 30px;
        height: 30px;
      }
      
    }

    .edit-btn {
      top: -2px;
      right: 20px;
    }

  }

}

.editable-info-item {
  width: 49%;
  margin-bottom: 22px;

  .info-item {
    width: 100%;
    margin-bottom: 0;
  }

}

.edit-btn,
.save-btn,
.delete-btn {
  @include reset-btn;

  position: absolute;
  right: 0;
}

.edit-btn,
.save-btn {
  top: 0;
}

.edit-btn {
  width: 20px;
  height: 20px;
}

.save-btn {
  z-index: 10;
  color: $accent-color;
  text-decoration: underline;
  font-size: rem(16px);
}

.delete-btn {
  top: 15px;
  right: -4px;
  width: 30px;
  height: 30px;
}

@include large {

  .edit-btn,
  .delete-btn {
    opacity: 0;
  }

  .editable-info-item {
    width: 47%;
  }

}
