.language-switcher {
  font-size: rem(14px);
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    float: left;

    &:last-child a {
      border-right: none;
    }

  }

  a {
    font-weight: 300;
    padding: 0 6px;
    border-right: 1px solid;
    text-transform: uppercase;
    text-decoration: none;

    &.active {
      font-weight: 700;
    }

  }

}

@include small {

  .language-switcher {
    font-size: rem(18px);
  }

}

@include large {

  .language-switcher a {
    padding: 0 7px;
  }

}
