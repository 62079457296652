.DayPicker {
  height: 320px;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 12px;
  overflow: hidden;
  box-shadow: $box-shadow;
}

.pick-date {
  // height: 320px;
  max-width: 300px;
  margin: 0 auto;

  .DayPicker__horizontal {
    margin-left: 0;
  }

}

.calendar-prev {
  left: 0;
}

.select-all-hours,
.clear-all-hours {
  @include reset-btn;

  color: $accent-color;
  text-decoration: underline;
}

.hours-wrapper button {
  background-color: $gray;
}

.start-end-date {
  margin-top: 0;
}

@include small {

  .DayPicker {
    margin-left: 0;
    margin-right: 0;
  }

  .pick-date {
    max-width: 320px;
  }

}

@include min-width(700px) {
  
  .pick-date {
    max-width: 100%;
    margin-left: 0;
  }

}

@include large {
  
  .pick-hour {
    float: left;
    max-width: 310px;
    padding: 10px;
    box-shadow: $box-shadow;

    ~ .form-group {
      float: left;
      width: calc(100% - 330px);
      margin-left: 20px;
    }

  }

}
