.billing-links {

  a {
    display: block;
    margin-bottom: 10px;
    color: $accent-color;
    font-size: rem(16px);
  }

}

.bigger-gap {
  column-gap: 100px;

  .btn {
    margin-top: 30px;
    margin-bottom: 40px;
  }

}

.phone-wrapper {
  position: relative;

  .btn-link {
    position: absolute;
    z-index: 2;
    right: 18px;
    bottom: 8px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .important {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 6px;
    margin-right: -10px;
  }

}

.important {
  width: 20px;
  height: 20px;
  font-size: rem(14px);
  color: $white;
  background-color: $error-color;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
}

@include large {

  .phone-wrapper .important {
    right: initial;
    left: 100%;
    margin-top: 0;
    margin-left: 10px;
  }

  .important {
    width: 30px;
    height: 30px;
    font-size: rem(22px);
  }

}