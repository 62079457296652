.circle {
  padding: 0 12px;
  color: $awards-color;
  float: left;

  svg {
    width: 68px;
    height: 68px;
  }

  p {
    margin: 10px 0 0;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: $awards-color;
    transition: stroke-dashoffset 1s ease 0s;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: $awards-color;
    font-size: rem(26px);
    dominant-baseline: central;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }


}

@include small {
  
  .circle {
    padding: 0 24px;
  }

}
