.restaurant-menu {
  margin-bottom: 25px;
  font-weight: 500;

  select {
    height: 38px;
    padding-bottom: 15px;
    font-size: rem(18px);
  }

  .form-group {
    margin-bottom: 24px;
  }

}


[class^="tour-"],
.fc-day .fc-timegrid-col-frame {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    background-color: $white;
  }

}

button {
  outline: none;
}
