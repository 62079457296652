.modal,
.react-confirm-alert-overlay {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-inner,
.react-confirm-alert-body {
  padding: 15px;
  background-color: $white;
}

.modal-inner {
  width: 320px;
  max-width: 850px;
  max-height: calc(100vh - 155px);
  overflow: auto;
}

.react-confirm-alert-body {
  max-width: 90vw;
  width: 500px;
  text-align: center;

  h1 {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 40px;
  }

  button {
    @include reset-btn;

    margin: 0 15px 10px;
    background-color: $accent-color;
    color: $white;
    padding: 10px 30px;
    text-transform: uppercase;
    font-weight: bold;

    &:last-child {
      margin-bottom: 0;
      background-color: $dark-gray;
    }

  }

}

@include medium {

  .modal-inner {
    width: auto;
  }

  .modal-inner,
  .react-confirm-alert-body {
    padding: 30px;
  }

  .react-confirm-alert-body button {
    margin: 0 25px;
    padding: 10px 40px;
  }


}

@include large {

  .modal-inner {
    margin-top: 94px;
  }

}
