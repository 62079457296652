.account-manager-work-hours {
  border-top: 1px solid $border-color;

  h3 {
    margin-bottom: 0;
    margin-right: 10px;
  }

}

.account-manager-info {
  margin-bottom: 10px;

  p {
    margin-top: 0;
  }

  .text-wrapper {
    width: 100%;

    h3 {
      margin-bottom: 5px;
    }

  }

}

.account-manager-img {
  flex-shrink: 0;
  width: 110px;
  height: 110px;
  margin-right: 20px;
  object-fit: cover;
}

.account-manager-contact {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .icon {
    margin-right: 10px;
  }

}

@include large {
  
  .account-manager-info {
    padding: 20px;
  }

  .account-manager-work-hours {
    display: flex;
    align-items: center;
  }

}
