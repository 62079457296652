.tooltip-wrapper {
  @include clearfix;
  @include reset-btn;
  position: relative;
}

.tooltip-content {
  position: absolute;
  z-index: 3;
  min-width: 150px;
  padding: 10px;
  background-color: $gray;
  color: $gray;
  box-shadow: $box-shadow;
  
  p {
    margin: 0;
    color: $primary-color;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &.top,
  &.top::before,
  &.bottom,
  &.bottom::before {
    left: 50%;
    transform: translateX(-50%);
  }

  &.top-left {
    left: -10px;
  }

  &.top,
  &.top-left {
    bottom: 100%;
    margin-bottom: 12px;

    &::before {
      top: 100%;
      border-width: 10px 8px 0 8px;
      border-color: currentColor transparent transparent transparent;
    }

  }

  &.bottom {
    top: 100%;
    margin-top: 12px;

    &::before {
      bottom: 100%;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent currentColor transparent;
    }

  }

  &.left,
  &.left::before,
  &.right,
  &.right::before {
    top: 50%;
    transform: translateY(-50%);
  }

  &.left {
    right: 100%;
    margin-right: 12px;

    &::before {
      left: 100%;
      border-width: 8px 0 8px 10px;
      border-color: transparent transparent transparent currentColor;
    }

  }

  &.right {
    left: 100%;
    margin-left: 12px;

    &::before {
      right: 100%;
      border-width: 8px 10px 8px 0;
      border-color: transparent currentColor transparent transparent;
    }

  }

  .tooltip-wrapper.white-bg & {
    background-color: $white;
    color: $white;
  }
}
