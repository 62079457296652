.add-img {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: $dark-gray;
  font-size: rem(16px);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.no-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $dark-gray;
  border-radius: 16px;
}

@include max-width(1024px) {

  .event-no-img {

    .event-block {
      margin: 100px auto;

      .img-wrapper {
        height: 160px;
        margin-top: -80px;
      }

    }

  }

}

@include large {

  .add-img {
    font-size: rem(18px);
  }
  
}
