.combinations-list {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid $border-color;
  text-align: center;

  th,
  td {
    padding: 5px;
    border-bottom: 1px solid $border-color;

    &:first-child {
      width: 100%;
      text-align: left;
    }

  }

  th {
    color: $lighter-text-color;
    font-size: rem(12px);
  }

  tbody tr {
    cursor: pointer;

    &:hover {
      background-color: $gray;
    }

    &.active {
      background-color: $accent-color-hover;
    }

  }

  .tooltip-wrapper {
    margin: -5px;
    padding: 5px;
  }

}

.btn-delete {
  display: block;
  margin: 10px auto 0;
  text-align: center;
  color: $error-color;
}
