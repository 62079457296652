.terms-heading {

  img {
    width: 100%;
  }

  .text-wrapper {
    max-width: 800px;
    margin-right: 20px;
  }

  .img-wrapper {
    flex-shrink: 0;
    margin: 0 auto;
  }

}

.giftcards-img {
  width: 405px;
  max-width: 100%;
}

.events-img {
  width: 220px;
}

.tasting-menus-img {
  width: 270px;
}

.terms-content {
  max-height: 583px;
  margin-top: 33px;
  margin-bottom: 45px;
  padding: 10px 20px;
  overflow: auto;
  background-color: $gray;
  box-shadow: inset 1px 1px 9px rgba(0, 0, 0, 0.2);
  color: #707070;
  font-size: rem(18px);

  h3,
  h4 {
    font-size: rem(20px);
  }

  h3 {
    margin-top: 0;
  }

  h4 {
    margin-bottom: 0;

    + p {
      margin-top: 0;
    }

  }

}

@include large {

  .terms-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-wrapper {
      margin: 0;
    }

  }

}
