.info-wrapper {
  position: absolute;
  top: 0;
  height: 45px;
  z-index: 1;
  left: 0;
  right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $white;
  border-bottom: 1px solid $border-color;
  text-align: center;
  color: $error-color;

  h3,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  h3 {
    margin: 0;
    font-size: rem(18px);
  }

  a {
    color: $accent-color;
  }

  svg {
    width: 23px;
    height: 23px;
    margin-right: 15px;
  }

  +.page-header {
    top: 45px;
  }

}

.language-switcher {
  display: flex;

  a.active {
    color: $accent-color;
  }

}

.page-header.not-logged {
  background: rgba(0, 0, 0, 0.5);

  .language-switcher {
    color: $white;

    a {
      border-color: $white;
    }

  }

  .logo svg {
    color: $white;
  }

  .mobile-menu-link span,
  .mobile-menu-link span::before,
  .mobile-menu-link span::after {
    background-color: $white;
  }

}

.sticky-info-wrapper {
  min-height: 48px;

}

.restaurant-name {
  margin: 0;
  font-size: rem(22px);
  line-height: 1.1;

  small {
    display: inline-block;
    margin-left: 5px;
  }

}

.switch-link {
  margin-left: 30px;
  font-size: rem(16px);
  text-transform: uppercase;
  font-weight: 500;
}

.sticky-info {
  position: sticky;
  top: 0;
  z-index: 5;
  margin-bottom: 30px;
  border-bottom: 1px solid $header-border;
  background-color: $white;
}

.sticky-info-left .icon {
  margin-right: 20px;
  flex-shrink: 0;
}

.page-content>.inner-wrapper:first-child {
  margin-top: 30px;
}

.header-right {
  margin-top: 22px;
}

@include small {
  .header-right {
    margin-top: 18px;
  }
}

@include larger {

  .main-nav {
    position: static;
    padding-top: 2px;
  }


  .header-right {
    margin-top: 28px;
  }

  .page-header {
    height: 85px;
  }

  .logo {
    margin-top: 18px;
  }

  .header-right {
    margin-top: 12px;
    max-width: calc(100% - 210px);
  }

  .mobile-menu {
    overflow: initial;
  }

  .page-header.not-logged {

    .main-nav {
      color: $white;
    }

  }


}