
.shadow-grid {
  margin-bottom: 40px;
}

@include medium {

  .shadow-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    row-gap: 40px;
    column-gap: 30px;
  }

}
