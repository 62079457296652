.slick-prev,
.slick-next {
  @include reset-btn;

  font-size: 0;
}

.slide-item {
  outline: none;
}

.restaurant-images {
  position: relative;
  overflow: hidden;
  margin-left: -14px;
  margin-right: -14px;

  img {
    position: relative;
    z-index: 2;
    display: block;
    max-width: 100%;
    height: 314px;
    margin: 0 auto;
    object-fit: cover;
  }

  .slide-item {
    position: relative;
    height: 100%;
    background-size: 0;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width : 100%;
      height: 100%;
      background: inherit;
      background-size: cover;
      background-position: center;
      filter: blur(10px);
      transition: all 2s linear;
      transform: scale(1.2);
    }

  }
  
}

@include small {

  .restaurant-images {
    margin-left: -30px;
    margin-right: -30px;
  }

}

@include large {

  .restaurant-images {
    margin-left: 0;
    margin-right: 0;
    max-width: 756px;

    img {
      height: 426px;
    }

    &:hover .custom-gallery-controls button {
      opacity: 1;
    }

  }

  .custom-gallery-controls {
  
    button {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      width: 62px;
      border: 0;
      backdrop-filter: blur(5px);
      outline: none;
      font-size: 0;
      cursor: pointer;
      transition: opacity 0.4s;
    }

    .btn-prev {
      left: 0;
      background: url(../../assets/images/icons/PrevArrow.svg) no-repeat center rgba(255, 255, 255, 0.6);
    }

    .btn-next {
      right: 0;
      background: url(../../assets/images/icons/NextArrow.svg) no-repeat center rgba(255, 255, 255, 0.6);
    }

  }

}
