.page-heading-post-creator {
  height: 162px;
  background: url('../../assets/images/post-creator-bg.jpg') no-repeat right center;
}

.post-restaurant-info {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: rem(13px);
  }

  span {
    font-size: rem(10px);
  }

  .avatar {
    width: 38px;
    height: 38px;
    margin-right: 6px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

}

.post-sizes {
  display: flex;
  justify-content: space-between;
  margin-top: 37px;
  margin-bottom: 50px;

  a {
    text-decoration: none;
  }

  > div {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

  }

}

.post-header {
  position: relative;
  padding: 10px;
}

.post-text {
  padding: 5px 10px 10px;
  
  p {
    font-size: rem(14px);
    margin: 0 0 10px;
  }

  img {
    width: 100%;
  }

}


.facebook-post,
.instagram-post {
  border: 1px solid $border-color;
  border-radius: 4px;
}

.facebook-logo,
.instagram-logo {
  position: absolute;
  top: 10px;
  right: 10px;
}

.facebook-post {
  width: 483px;
  height: 414px;

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    padding: 5px 44px;
    border-top: 1px solid $border-color;
    font-size: rem(11px);
    color: $lighter-text-color;

    span {
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 5px;
    }

  }

}

.instagram-post,
.instagram-story {
  height: 429px;

  .avatar {
    width: 28px;
    height: 28px;
  }

}

.instagram-post {
  width: 332px;

  footer {
    padding: 12px;

    span {
      float: left;
      margin-right: 16px;
    }

    .bookmark {
      float: right;
      margin-right: 0;
    }

  }

  .post-text {
    padding: 0;
  }

}

.instagram-story {
  width: 241px;
  background: url(../../assets/images/instagram-story.jpg) no-repeat center top/100%;

  .post-header {
    padding-top: 7px;
    color: $white;
  }

  .post-restaurant-info {
    width: 100%;
    padding-top: 10px;
    border-top: 2px solid $white;

    p {
      font-size: rem(11px);
    }


  }

}

@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;700&family=Jost:ital,wght@0,400;0,700;1,400;1,700&family=Lobster&display=swap');
// font-family: 'El Messiri', sans-serif;
// font-family: 'Jost', sans-serif;
// font-family: 'Lobster', cursive;

.preload-font-1,
.preload-font-2,
.preload-font-3 {
  position: fixed;
  right: 100%;
  top: 100%;
}

.preload-font-1 {
  font-family: 'El Messiri', sans-serif;
}

.preload-font-2 {
  font-family: 'Jost', sans-serif;
}

.preload-font-3 {
  font-family: 'Lobster', cursive;
}

.small-inner-wrapper {
  max-width: 1024px;
}

.post-creator-form {
  // position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.left-post-creator-form {
  width: 224px;
}

.right-post-creator-form {
  width: 273px;

  .label {
    position: relative;
    z-index: 2;
    display: block;
    font-size: rem(12px);
    color: $lighter-text-color;
  }

}

.center-post-creator-form {
  width: calc(100% - 537px);

  &.full-screen {
    width: 100%;
  }

}

.no-image {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
  font-size: rem(16px);
}

.choose-from-images {
  height: calc(100vh - 400px);
  overflow: auto;

  button {
    @include reset-btn;

    position: relative;
    margin-bottom: 20px;
  }

  img {
    display: block;
    max-width: 100%;
  }

}

.unsplash-credit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 5px;
  color: $white;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: rem(12px);
}

.canvas-width-wrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;

  &.can-drag {
    cursor: move;
  }

}

.post-canvas-container {
  position: relative;
  height: 0;
  width: 100%;
  background-color: $gray;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    background-size: cover;
  }

}


.left-post-creator-form,
.right-post-creator-form,
.center-post-creator-form {
  border: 1px solid $border-color;
  
  h2 {
    margin: 0;
    font-size: rem(14px);
  }

}

.post-elements-wrapper {
  padding: 0 20px;
}

.element-button {
  @include reset-btn;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid $border-color;
  text-align: left;
  font-size: rem(12px);

  span {
    width: 38px;
  }

  .icon {
    color: inherit;
  }

  &.selected {
    position: relative;
    background-color: $accent-color;
    color: $white;
    font-weight: bold;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -20px;
      right: -20px;
      top: 0;
      bottom: 0;
      background-color: $accent-color;
    }

  }

  &:last-child {
    border-bottom: none;
  }

}

.toggle-buttons {
  display: flex;
  margin-bottom: 10px;
  background-color: $gray;
  border-radius: 50px;
  overflow: hidden;

  button {
    @include reset-btn;

    width: 100%;
    padding: 10px 5px;
    border-radius: 50px;
    font-size: rem(20px);
  }

  .active {
    background-color: $accent-color;
    color: $white
  }

  .icon {
    color: inherit;
  }

}

.bold-option {
  font-weight: bold;
}

.italic-option {
  font-style: italic;
}

.underline-option {
  text-decoration: underline
}

.icon-select {

  button {
    @include reset-btn;
  }

  .icon {
    width: 40px;
  }

}

.editable-canvas-text {
  position: absolute;
  z-index: 5;
  border: 1px solid #4082A4;
  background: rgba(255, 255, 255, 0.5);

  textarea {
    width: 100%;
    height: 100%;
    overflow: hidden;
    resize: none;
    padding: 0;
    border: none;
    background: transparent;

    &:focus {
      outline: 0;
      appearance: none;
    }

  }

}

.upload-file {
  margin-top: 20px;

  input {
    display: none;
  }

}

.range-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: $gray;
  font-size: rem(12px);
  border-radius: 50px;
}

.btn-download {
  float: right;
  margin: 30px 10px;
}

.image-resize-slider {
  position: absolute;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -44px;
}

.btn-add-element {
  margin: 0;
  display: flex;
  padding: 5px 10px;
}

.color-form-group {
  margin-bottom: 10px;

  input {
    border: none;
    background-color: transparent;
    height: 30px;
    width: 60px;
    margin-left: -4px;
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
  }

}

.btn-delete-element {
  margin-top: 20px;
  width: 100%;
  text-align: center;

  .icon {
    margin-right: 10px;
  }

}

.selected-icon {
  @include reset-btn;

  width: 100%;
  border-bottom: 1px solid;
  text-align: left;

  
  svg {
    height: 30px;
  }

}

.icon-select {
  position: absolute;
  z-index: 4;
  padding: 10px;
  background: white;
}

.btn-reposition .icon {
  margin-right: 5px;
}

.filter-wrapper {
  position: relative;
  margin-bottom: 10px;
}

.selected-filter {
  @include reset-btn;

  width: 100%;
  border-bottom: 1px solid;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
  background: url(../../react-web-ui/assets/images/icons/ArrowDown.svg) no-repeat center right;
}

.filter-options {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 100%;
  background-color: $white;
  height: 400px;
  overflow: auto;

  button {
    @include reset-btn;

    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  img {
    width: 120px;
    margin-right: 10px;
  }

}
