.main-nav {

  li {
    position: relative;
  }

  .dd-btn {
    padding-right: 35px;
  }

}

.dd-btn {
  @include reset-btn;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 10px;
    background: url(../../../react-web-ui/assets/images/icons/ArrowDown.svg) no-repeat center/100%;
  }

}

@include min-width(1180px) {

  .main-nav .dd-btn {
    padding-right: 25px;
  }

  .main-nav li {
    margin: 0 15px;
  }

  .dd-btn::before {
    right: 0;
  }

}
