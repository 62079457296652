.StripeElement {
  margin-top: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid $primary-color;
}

.pricing-block {
  width: 100%;

  h3 {
    flex-shrink: 0;
    margin: 0;
    font-size: rem(48px);
    text-transform: uppercase;
    word-break: break-all;
    text-align: left;
  }

  footer {
    display: block;
    flex-shrink: 0;

    .btn {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

  }

  .shadow-block {
    background-color: $white;
  }

  .pricing-header {
    margin-right: 40px;
    padding: 0;
    background-color: transparent;
    color: $accent-color;
  }

  .pricing-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .pricing-option {
    border-bottom: 0;
  }

  .icon {
    width: 20px;
    margin-right: 10px;
  }

  .plan-price {
    margin-bottom: 12px;
  }

  &.horizontal {
    margin-top: 45px;

    footer {
      width: 186px;
      margin: 0 auto;
    }

    .icon {
      color: $discount-color;
    }

    .toggle-status {
      width: 100%;
    }

  }

  &.vertical {

    h3 {
      font-size: rem(42px);
    }

    h4 {
      margin: 0;
      font-size: rem(18px);
    }

    .ribbon-above {
      text-transform: none;
      font-size: rem(12px);
      text-align: left;
    }

    .pricing-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-right: 0;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid $border-color;
    }

    .pricing-content {
      display: block;
      margin-bottom: 10px;
      padding-bottom: 15px;
      border-bottom: 1px solid $border-color;
    }

    .plan-price {
      margin-bottom: 0;
      font-size: rem(28px);

      small {
        font-size: rem(16px);
      }

    }

    .billing-type {
      margin-top: -8px;
      color: $primary-color;
    }

    .pricing-option {
      margin-bottom: 5px;
      padding: 0;
      justify-content: flex-start;
    }

    .shadow-block {
      padding: 14px 18px 30px;
    }

  }

}

.free-trial-text {
  text-align: center;
  font-size: rem(18px);
}

.ribbon-above {
  display: block;
  margin-bottom: -16px;
  padding: 5px 20px 20px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: $accent-color;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: $white;
  text-align: right;
}

.plan-price {
  font-size: rem(32px);
  font-weight: bold;

  small {
    margin-left: 12px;
    font-size: rem(18px);
    font-weight: normal;
    vertical-align: super;
  }

}

.stripe-form {
  // margin-top: 40px;

  h2 {
    font-size: rem(16px);

    &:first-child {
      margin-top: 0;
    }

  }

}

.tax-fields {
  display: flex;

  .form-group {
    width: 100%;

    &:first-child {
      width: 100px;
      margin-right: 20px;
    }

  }

}

.stripe-img {
  height: 16px;
  margin-left: 5px;
}

.loader-wrapper {
  position: relative;
  height: 260px;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

@include large {
  
  .subscriptions-title {
    display: flex;
    justify-content: space-between;

    .section-title,
    .pricing-period {
      margin: 0;
    }

  }

  .pricing-block {

    footer {
      margin: 0;
    }

    &.horizontal {

      h3 {
        width: 146px;
      }

      .shadow-block {
        flex-direction: row;
        align-items: flex-start;
      }

      .pricing-content {
        padding-right: 20px;
      }

    }

  }

  .stripe-form .lg-two-cols .col {
    width: 35%;
  }

}
