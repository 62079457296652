.bookings-fields {
  margin-top: 20px;
  padding: 10px 10px 0;
  background-color: $white;
  box-shadow: $box-shadow;

  strong {
    display: block;
  }

  .pick-date {
    max-width: 100%;
  }

  .DateInput {
    width: 100px;
  }

  .DateInput_input {
    padding-left: 0;
    padding-right: 0;
  }

  .DateRangePickerInput_arrow {
    margin: 0 5px;
  }

  .btn {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }

  .dropdown-container {
    border: none;
    font-size: rem(18px);

    &:focus-within {
      box-shadow: none;
    }

  }

  .dropdown-heading {
    padding: 0;
  }

  .dropdown-content {
    min-width: 270px;
  }

}

.fields-wrapper>div {
  margin-bottom: 10px;
}

.search-query input {
  border-bottom: 0;
}

.btn-print {
  margin-left: 0;
  margin-bottom: 30px;
}

.page-heading-bookings,
.page-heading-guests {

  h1 {
    position: relative;
  }

  .btn-link {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    font-size: rem(16px);
  }

}


.example-sms,
.example-email {
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 15px;
  line-height: 1.5;

  p {
    margin: 0;
  }

}

.example-sms {
  background-color: $gray;
}

.example-email {
  box-shadow: $box-shadow;
  white-space: pre-line;
}

.btn-export {
  float: right;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: rem(18px);
}

@include large {

  .bookings-fields,
  .fields-wrapper {
    display: flex;
    align-items: center;
  }

  .bookings-fields {
    padding: 10px;
    justify-content: space-between;

    strong {
      margin-right: 10px;
    }

    .multi-select {
      width: 70%;
    }

    .btn {
      width: 140px;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
    }

    .DateInput_input {
      font-size: rem(16px);
    }

    .DateInput {
      width: 90px;
    }

  }

  .fields-wrapper {
    width: calc(100% - 160px);

    >div {
      display: flex;
      align-items: center;
      max-width: 25%;
      margin-bottom: 0;
      padding-right: 20px;
      margin-right: 10px;
    }

  }

  .page-heading-bookings,
  .page-heading-guests {

    .btn-link {
      font-size: rem(18px);
    }

  }

}

@media print {

  body {
    padding-top: 62px;
  }

  .info-wrapper,
  .header-right,
  .right-column,
  .bookings-table th:last-child,
  .bookings-table td:last-child,
  .dropdown-heading-dropdown-arrow,
  .page-heading .bookings-fields .btn,
  .btn-print,
  .sticky-info,
  .page-footer,
  .page-heading .notifications {
    display: none;
  }

  .fields-wrapper {
    overflow: hidden;

    >div {
      float: left;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .inner-wrapper,
  .smaller-inner-wrapper,
  .big-inner-wrapper,
  .global-wrapper {
    padding: 0;
  }

  .page-header {
    height: 62px;
  }

  .info-wrapper~.inner-wrapper,
  .info-wrapper~.big-inner-wrapper {
    padding-top: 0;
  }

  .logo {
    position: static;
    float: none;
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 206px;
  }

  .page-heading {
    padding: 8px 0;
    margin-bottom: 0;
    background-color: transparent;
  }

  .main-column {
    width: 100%;
  }

  .bookings-table table tbody tr.selected:nth-child(odd) {
    background-color: $gray;
  }

  .bookings-table table tbody tr.selected {
    background-color: $white;
    color: inherit;
  }

  #search_query {
    padding-left: 0;
  }

  .bookings-fields {
    display: block;
    padding: 0;
    box-shadow: none;

    strong {
      margin-top: 7px;
    }

    strong,
    .multi-select,
    .DateRangePicker {
      margin-right: 10px;
      float: left;
    }

    >div {
      display: inline-block;
      margin-right: 0;
      border-right: none;
      font-size: 12px;
    }

    .DateInput {
      width: 80px;
    }

    .DateInput_input,
    .dropdown-container {
      font-size: 14px;
    }

    .DateInput_input {
      padding: 4px 0;
      border-bottom: none;
    }

    .DateRangePickerInput_arrow_svg {
      height: 18px;
      width: 18px;
    }

    .dropdown-heading {
      height: 32px;
    }

    .form-group {

      input {
        height: 32px;
      }

      svg {
        display: none;
      }

    }

  }

  .pill-label {
    background-color: transparent;
    color: inherit;
    font-weight: bold;
  }

  .hero-section {
    display: none;
  }

  .page-header.not-logged {
    background: transparent;
  }

  .page-header.not-logged .logo svg {
    color: $black;
  }

  .info-wrapper+.page-header {
    top: 0;
  }

  .pricing-table:first-child,
  .btn-show-all-features,
  .btn-show-additional,
  .fb_dialog {
    display: none;
  }

  table {
    page-break-after: auto
  }

  table tr {
    page-break-inside: avoid;
    page-break-after: auto
  }

  table td {
    page-break-inside: avoid;
    page-break-after: auto
  }

  table thead {
    display: table-header-group
  }

  table tfoot {
    display: table-footer-group
  }

  .pricing-header {
    width: 220px;
  }

  .pricing-option .icon {
    display: block;
    margin: 0 auto;
  }

  .info-wrapper~.page-content {
    padding-top: 0;
  }

  .pricing-header {
    color: $black;
  }

}