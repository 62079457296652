.rating-summary-header p {
  color: $lighter-text-color;
}

.rating-summary {
  width: 100%;
  border: 1px solid $border-color;
  border-collapse: collapse;

  th,
  td {

    &:first-child {
      text-align: left;
    }

  }

  th {
    padding: 10px 4px 5px;
    font-size: rem(12px);
    color: $lighter-text-color;
    border-bottom: 2px solid $border-color;
  }

  td {
    padding: 12px 2px;
    text-align: center;
    border-top: 1px solid $border-color;
  }

  tbody tr:nth-child(odd) {
    background-color: $gray;
  }

  .star-ratings {
    margin-right: 0;
    margin-left: 2px;
    white-space: nowrap;
  }

}

.open-reviews {
  @include reset-btn;

  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: rem(25px);
  font-weight: 500;
  border-bottom: 1px solid $border-color;

  span {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-top: 10px;
    transition: all 0.3s;
  }

  &.open  .icon {
    transform: rotate(180deg);
  }

}

.site-logo {
  max-height: 50px;
  max-width: 206px;
  margin-right: 10px;
}

.right-column .site-logo {
  margin-bottom: 20px;
}

.reply-wrapper {
  margin-bottom: 30px;

  svg {
    max-height: 30px;
    width: auto;
    margin-bottom: 5px;
  }

}

.outside-review {
  
  .review-item {
    border-bottom: 2px solid $accent-color;
  }

  .btn {
    margin-left: 0;
    margin-right: 20px;
    padding: 6px 30px;
    font-size: rem(14px);
  }

}

.google-review {
  --accent-color: #DB4437;
}

.facebook-review {
  --accent-color: #3b5998;
}

.tripadvisor-review {
  --accent-color: #00af87;

  .avatar {
    display: none;
  }

}

.review-links {
  margin-bottom: 30px;
}

@include large {

  .rating-info,
  .rating-summary-header  {
    padding: 20px;
  }

  .total-rating {
    display: flex;
    justify-content: space-between;
  }
  
  .rating-summary-header p {
    margin-bottom: 0;
  }

  .rating-summary {

    th {
      padding: 16px 7px 5px;
    }

    .star-ratings {
      margin-left: 8px;
    }

  }

  .reply-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      order: 1;
    }

  }

}
