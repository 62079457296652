.review-item {
  position: relative;
  margin-bottom: 25px;
  padding: 15px 15px 30px;
  background-color: $gray;

  .tip {
    font-weight: 500;
  }

}

.reply-item {
  margin-left: 50px;
  word-break: break-word;
}

.review-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-weight: 500;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .avatar {
    width: 48px;
    height: 48px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $header-border;
    overflow: hidden;
  }

}

.review-text {
  line-height: 1.36;

  p {
    margin-top: 0;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  .star-ratings {
    margin-top: -10px;
  }

}

.upvote-review {

  button {
    margin-right: 20px;
  }

  .translate {
    display: block;
    margin-right: 0;
    color: $primary-color;
  }

}

@include large {

  .upvote-review {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 10px;

    .translate {
      float: right;
    }

  }

}
