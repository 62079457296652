.current-menu-item {

  .price {
    position: absolute;
    right: 30px;
    top: 0;
  }

  &:hover .edit-btn,
  &:hover .delete-btn {
    opacity: 1;
  }

  &::after {
    margin-left: -12px;
    margin-top: -2px;
  }

}

.menu-item-group,
.current-menu-item {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background: url(../../assets/images/icons/Drag.svg) no-repeat center/100%;
    opacity: 1;
    background-color: $gray;
  }

}

.menu-item-group {

  &::after {
    margin-left: -10px;
  }

}

.draggable-item,
.draggable-inputs {
  position: relative;
  outline: none;

  &.dragged {
    color: $lighter-text-color;
  }

}

@include max-width(1024px) {

  .current-menu-item .menu-item {
    padding-bottom: 10px;

    small {
      margin-bottom: 0;
    }

  }

  .draggable-item:nth-child(even)::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -10px;
    right: -10px;
    top: -2px;
    bottom: 2px;
    background-color: $gray;
  }

}

@include large {

  .current-menu-item::after {
    margin-top: -10px;
  }

  .current-menu-item,
  .menu-item-group {

    &::after {
      top: 0;
      left: 0;
      width: 20px;
      margin-left: -20px;
      opacity: 0;
    }

    &:hover::after {
      opacity: 1;
    }

  }

  .draggable-item,
  .draggable-inputs {

    &.dragged .current-menu-item::after,
    &.dragged::after {
      opacity: 1;
    }

  }

  .draggable-item {

    &.dragged::before,
    &:hover::before {
      content: '';
      position: absolute;
      z-index: 0;
      left: -20px;
      right: -10px;
      top: -10px;
      bottom: 4px;
      background-color: $gray;
    }

  }

}
