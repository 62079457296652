.chosen-set {
  margin-bottom: 10px;

  button {
    @include reset-btn;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  > div {
    margin-top: 8px;
    padding-left: 4px;
  }

}

.arrow {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  font-size: rem(16px);

  &.rotate {
    position: relative;
    top: 2px;
    transform: rotate(90deg);
  }

}

.quantity {
  margin-right: 5px;
}
