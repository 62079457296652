.restaurant-title {
  padding-right: 0;
}

.info-item .text {
  width: 100%;
}

.awards-block {
  
  .form-group {
    background-color: transparent;

    input {
      border-color: $white;

      @include placeholder {
        color: $white;
      }

    }

  }

}

.restaurant-menu-edit h3,
.menu-items-wrapper {
  display: block;
}

.restaurant-menu-edit {

  h3 {
    margin-top: 0;
    font-weight: 500;
  }

  .menu-item {
    padding-bottom: 30px;
  }

  .grid {
    position: relative;
    z-index: 2;
    padding-right: 100px;
  }

}

.properties {
  margin-bottom: 30px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }

  button {
    @include reset-btn;
  }

}

@include large {

  .restaurant-description,
  .awards-block {

    .editable-text.editing {
      width: 100%;
    }

    .invertible-fields {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &.inverted {
        display: flex;
      }

    }
    
    .form-group {
      width: 48.3%;
    }

  }

}
