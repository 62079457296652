.calendar-prev,
.calendar-next {
  position: absolute;
  top: -6px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  svg {
    width: 22px;
    height: auto;
  }

}

.calendar-prev {
  left: -10px;

  svg {
    transform: rotate(90deg);
  }

}

.calendar-next {
  right: 0;

  svg {
    transform: rotate(-90deg);
  }

}

.pick-date {

  .CalendarDay__default {
    background-color: $accent-color-hover;
    border: 4px solid $white;
  }

  .CalendarDay__default:hover {
    border: 4px solid $white;
  }

  .CalendarDay__selected, 
  .CalendarDay__selected:active, 
  .CalendarDay__selected:hover {
    background-color: $accent-color;
    border: 4px solid $white;
  }

  .CalendarDay__default:hover {
    background-color: $accent-color;
    color: $white;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background-color: $accent-color;
    color: $white;
  }

  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover,
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    background-color: $gray;
    color: $dark-gray;
  }

  .CalendarMonth_caption {
    padding-top: 0;
    padding-bottom: 43px;
    color: $primary-color;

    strong {
      font-weight: 500;
    }

  }

  .DayPicker_weekHeader_li {
    margin: 0 0.5px;

    small {
      font-size: rem(16px);
      font-weight: 500;
      color: $primary-color;
    }

  }

  .DayPicker_weekHeader {
    top: 36px;
    margin-left: -10px;
  }

  .CalendarMonthGrid__horizontal {
    left: 0;
  }

  .CalendarDay {
    font-size: rem(16px);
    font-weight: 500;
  }

  .DayPicker__horizontal {
    margin: 0 auto;
  }

  .CalendarMonth_caption {

    >div>div {
      margin: 0 5px;
    }

    select {
      height: 24px;
      border-bottom: 0;
      padding-right: 20px;
    }

  }

}

.pick-hour h3 {
  font-size: rem(16px);
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 0;
}

.hours-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  font-weight: 500;

  button {
    width: 66px;
    height: 38px;
    margin: 2px;
    padding: 10px 0;
    background-color: $accent-color-hover;
    text-align: center;
    font-size: rem(16px);
    cursor: pointer;
    border: none;
    outline: none;
  }

  button[disabled] {
    background-color: $gray;
    color: $lighter-text-color;
    cursor: default;
  }

  .selected-hour {
    background-color: $accent-color;
    color: $white;
  }

}
