.special-offer-block,
.add-special-offer-block {

  .img-wrapper {
    min-height: 200px;
  }

  .dates-validity {
    display: block;
    text-align: center;
    font-size: rem(18px);
    margin-bottom: 10px;
  }

  .validity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-size: rem(16px);

    .icon {
      width: 30px;
    }

  }

  .multiple-buttons .btn {
    min-width: 40%;
    padding-left: 15px;
    padding-right: 15px;
  }

}

.discount-hours {
  display: inline-block;
  margin-right: 5px;
  white-space: nowrap;
}

.discount-days {
  display: inline-block;
  margin-right: 5px;

  &:not(:last-child)::after {
    content: ',';
  }

}

@include large {

  .special-offer-block,
  .add-special-offer-block {

    .dates-validity {
      font-size: rem(22px);
    }    

  }

}
