.restaurant-menu {
  margin-bottom: 25px;
  font-weight: 500;

  select,
  h3 {
    font-size: rem(18px);
  }

  h3 {
    display: none;
  }

  select {
    height: 38px;
    padding-bottom: 15px;
  }

  .form-group {
    margin-bottom: 24px;
  }

}

.menu-items-wrapper {
  display: none;

  &.show {
    display: block;
  }

}

.legend {
  margin-top: 28px;
  font-size: rem(12px);
  
  .icon {
    color: $accent-color;
    width: 30px;
  }

}

.no-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  font-size: rem(18px);
  font-weight: bold;
  background-color: $gray;
  border-radius: 16px;

  svg {
    margin-right: 15px;
  }

}

@include large {

  .restaurant-menu {

    h3 {
      display: block;
      margin-top: 0;
      padding-bottom: 5px;
      font-weight: 500;
      border-bottom: 1px solid $primary-color;
    }

  }

  .menu-items-wrapper {
    display: block;
    margin-bottom: 62px;

    &:last-child {
      margin-bottom: 30px;
    }

  }

  #menu-block.hidden {
    position: relative;
    height: 900px;
    overflow: hidden;

    .expand-menu {
      display: block;
    }

  }

  .expand-menu {
    @include reset-btn;
    
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    width: 100%;
    height: 136px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.94) 51%, var(--white) 100%);
    background-color: transparent;
    font-size: rem(16px);
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
  }

}
