.reply-btn {
  @include reset-btn;

  display: block;
  color: $accent-color;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;

  .icon {
    margin-right: 10px;
  }

}

.form-group.gray-bg {
  background-color: $gray;
}

.reply-form {
  margin-top: 20px;
  margin-bottom: 40px;

  .btn {
    margin-left: 0;
  }

}

.reply-text-symbols {
  margin-bottom: 10px;
}
