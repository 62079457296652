.menu-item {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
  
  p {
    margin: 0;
  }

  small {
    display: block;
    margin-bottom: -10px;
    font-weight: normal;
    font-style: italic;
  }

  sup {
    margin-left: 5px;
    font-size: rem(9px);
  }

  .icon {
    height: 15px;
    width: auto;
    margin-right: 6px;
    vertical-align: baseline;
    color: $accent-color;
  }

  .text {
    width: calc(100% - 25px - 40px);
    word-break: break-word;
  }

  .price {
    margin-left: 5px;
  }

  &:first-child {
    margin-top: 0;
  }

}
