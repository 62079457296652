
.select-value-block {

  input,
  button {
    height: 48px;
    outline: none;
  }

}

.value-select-wrapper {
  display: flex;
  flex-wrap: wrap;
}

button.value-select {
  border: 1px solid $border-color;
  background-color: $white;
  cursor: pointer;
  text-align: center;

  &.selected {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-width: 2px;
    border-color: $accent-color;
  }

}

.value-select {
  margin-right: 5px;
  flex-grow: 1;
  color: $lighter-text-color;

  p.error {
    position: absolute;
  }

  &.selected {
    color: $primary-color;

    input {
      border-color: $accent-color;
    }

  }

  &.form-group {
    width: 110px;
    flex-shrink: 0;
    margin-right: 0;
  }

}

@include small {

  .value-select {
    margin-right: 14px;

    &.form-group {
      width: 142px;
    }

  }

}
