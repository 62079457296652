.shifts-calendar-wrapper {
  overflow: auto;

  .fc {
    min-width: 664px;
    max-height: 35vh;
  }

  .fc .fc-col-header-cell-cushion,
  .fc .fc-timegrid-slot-label {
    padding-top: 14px;
    padding-bottom: 14px;
    height: 44px;
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: inherit;
  }

  .fc-v-event {
    padding: 6px 10px;
    background-color: rgba(#FFAE42, 0.5);
    border-radius: 10px;
    border-color: $white;
  }

  .fc-event-main p {
    margin: 0;
    font-weight: bold;
    font-size: rem(12px);
  }

  .fc-v-event:not(.fc-event-selected) .fc-event-resizer {
    left: 20px;
    right: 20px;
    height: 20px;
  }

  .fc-v-event:not(.fc-event-selected) .fc-event-resizer-start {
    top: -10px;
  }

  .fc-v-event:not(.fc-event-selected) .fc-event-resizer-end {
    bottom: -10px;

    &::before {
      top: 3px;
    }

  }

  .fc-event .fc-event-resizer {
    display: block;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      border-radius: 10px;
      background-color: $white;
    }

  }

}

.delete-shift {
  position: absolute;
  z-index: 10;
  top: -6px;
  right: -18px;
  width: 27px;
  height: 27px;
  background-color: $accent-color;
  border: 2px solid $white;
  color: $white;
  font-size: rem(22px);
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  line-height: 1;
}

.shift-details {
  position: sticky;
  top: 50px;
  border: 1px solid $border-color;

  .section-sub-title {
    margin-bottom: 0;
    padding: 14px;
    border-bottom: 1px solid $border-color;
  }

}

.shift-details-content {
  padding: 14px;
}

.fc-timegrid-slots tr .fc-timegrid-slot-label-frame {
  font-weight: bold;
}

.day-and-time {
  display: flex;
  margin-top: 10px;

  .form-group {
    width: 100%;
    margin-bottom: 0;

    &:first-child {
      width: 60px;
      margin-right: 15px;
    }

  }

}

@include medium {

  .shifts-calendar-wrapper .fc {
    max-height: 60vh;
  }

}

@include large {

  .shifts-calendar-wrapper .fc {
    min-width: 0;
    max-height: initial;
  }

}
