.edit-recepient-btn {
  @include reset-btn;
}

.edit-recepient-btn {
  display: flex;
  align-items: center;
  color: $accent-color;
  font-size: rem(13px);

  .icon {
    margin-right: 6px;
  }

}

.expand-bookings-btn {
  float: left;
}

.billing-info-block {
  margin-bottom: 40px;
  text-align: right;

  p {
    margin-bottom: 0;
  }

  .table {
    margin-bottom: 20px;
  }

  .text {
    margin-top: 0;
    font-size: rem(16px);
  }

}

.total-amount-header {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;

  .text-accent {
    font-size: rem(28px);
  }

}

.amount-info-block {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.amount-info {
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

}

.total-amount {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 0;
  margin-left: auto;

  .left,
  .right {
    margin-bottom: 5px;
  }

  .right {
    width: calc(100% - 100px);
  }

  .left {
    width: 95px;
  }

}

.recepient-info {
  text-align: left;
}

.completed-payment {
  padding: 10px 0;

  &:first-child {
    border-top: 2px solid $border-color;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

}

@include large {
  
  .billing-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .billing-info-block {
    padding: 10px 30px 30px;

    .table {
      display: flex;
    }

  }

  .completed-payments-heading,
  .completed-payment {
    padding: 20px;
  }

  .completed-payments-heading h3 {
    margin-bottom: 0;
  }

  .total-amount-header {
    padding-top: 0;
  }

  .dates-table {
    float: right;

    .table-column {
      margin-left: 30px;
    }

  }

  .amount-info-block {
    clear: both;
    padding-top: 40px;
    border-bottom: 40px;
  }

  .amount-info {
    padding: 15px 0;
  }

  .recepient-info {
    justify-content: center;

    .table-column {
      margin: 0 15px;
    }

  }

}
