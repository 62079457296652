.counter {
  display: flex;
  align-items: center;
  font-size: rem(17px);
  text-align: center;
  font-weight: 700;

  button {
    width: 40px;
    height: 40px;
    background-color: $gray;
    border: 0;
    cursor: pointer;
  }

  button[disabled] {
    cursor: default;
  }

  p {
    margin: 0;
  }

}

.count {
  width: 40px;
}
