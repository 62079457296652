.custom-phone-input {

  .react-tel-input .form-control {
    width: 100%;
    border: 0;
    border-radius: 0;
    padding-left: 56px;
    border-bottom: 1px solid $primary-color;
  }

  .react-tel-input .flag-dropdown {
    background-color: $white;
  }

  .react-tel-input .flag-dropdown,
  .react-tel-input .country-list .search {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $primary-color;
  }

  .react-tel-input .selected-flag {
    width: 44px;
  }

  .react-tel-input .selected-flag .arrow {
    left: 24px;
    top: -7px;
  }

  .react-tel-input .country-list .search-box {
    width: calc(100% - 32px);
    border: none;
  }

}

.form-group.error {

  .custom-phone-input .react-tel-input .form-control,
  .custom-phone-input .react-tel-input .flag-dropdown {
    border-color: $error-color;
  }

}
