.forgotten-pass-form {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;

  .form-group,
  .btn {
    margin: 0;
  }

  .form-group {
    width: 100%;
  }

  .btn {
    height: 46px;
  }

}

.forgotten-pass-btn {
  display: block;
  margin: 15px auto 0;
}
