.inner-wrapper,
.smaller-inner-wrapper,
.big-inner-wrapper {
  @include clearfix;

  margin: 0 auto;
  padding: 0 14px;
}

.inner-wrapper,
.smaller-inner-wrapper {
  max-width: 1348px;
  width: 100%;
}

.small-page-content {
  max-width: 600px;
  margin: 100px auto;

  table {
    margin: 0 auto;
  }

  td:first-child {
    text-align: right;
    color: $lighter-text-color;
  }

  a {
    display: block;
    margin-top: 30px;
  }

  footer {
    margin-top: 30px;
    margin-bottom: -30px;
  }

  .icon {
    margin-bottom: 30px;
  }

}

.big-inner-wrapper {
  max-width: 1920px;
}

.restaurant-list-grid,
.cities-list-grid,
.smaller-list-grid {
  display: grid;
  column-gap: 34px;
  row-gap: 40px;
  margin-bottom: 70px;
}

.restaurant-list-grid {
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
}

.cities-list-grid {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.smaller-list-grid {
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.city-block-loader svg {
  width: 100%;
  height: auto;
}

.text-page-content {
  max-width: 750px;
  margin: 0 auto 40px;

  .text-content {
    font-weight: 500;
  }

}


@include small {

  .inner-wrapper,
  .smaller-inner-wrapper,
  .big-inner-wrapper {
    padding: 0 30px;
  }

  .restaurant-list-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

}

@include large {

  .smaller-inner-wrapper {
    padding: 0 64px;
  }

  .lg-two-cols,
  .lg-three-cols {
    display: flex;
    justify-content: space-between;
  }

  .lg-two-cols {
    flex-wrap: wrap;

    .col {
      width: 48.3%;
    }

  }

  .lg-three-cols .col {
    width: calc(33% - 10px);
  }

  .grid {
    clear: both;
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(10, 1fr);
  }

  .grid-6 {
    column-gap: 20px;
    grid-template-columns: repeat(6, 1fr);
  }

  .restaurant-list-grid {
    column-gap: 60px;
  }

  .smaller-list-grid {
    column-gap: 40px;
  }

  .column-layout {
    display: flex;
    justify-content: space-between;
  }

  .main-column {
    width: calc(100% - 420px);
  }

  .right-column {
    width: 380px;
  }

  @for $i from 1 through 10 {

    .grid-col-#{$i} {
      grid-column: span #{$i};
    }

  }

}
