.event-block,
.tasting-menu-block {
  max-width: 540px;
  padding-bottom: 35px;

  h2 {
    margin-top: 30px;
    font-weight: bold;
  }

  p {
    display: block;
    color: $primary-color;
  }

  .menu-price {
    font-style: normal;
    font-weight: bold;
    font-size: rem(22px);
  }

  .text-wrapper {
    text-align: center;
  }

  .btn {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    display: inline-block;
    padding-left: 40px;
    padding-right: 40px;
  }

}

.event-block {
  margin: 176px auto 240px;

  h2 {
    font-size: rem(26px);
  }

  h2,
  p {
    text-align: left;
  }

  .img-wrapper {
    height: 351px;
    margin-top: -176px;
  }

  .menu-content {
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;
  }

  .btn {
    text-align: center;
  }

}

.event-info {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
  margin-bottom: 20px;
  font-weight: 500;
  border-bottom: 1px solid $border-color;

  span {
    display: flex;
    align-items: center;
    margin-right: 10px;
    flex-shrink: 1;
  }

  svg {
    width: 40px;
  }

}

.event-text {
  margin-bottom: 30px;
  text-align: left;

  p {
    margin-bottom: 20px;
    font-size: rem(14px);
  }

}

.events-list-grid,
.tasting-menus-list-grid {
  padding-top: 20px;
}

@include large {

  

  .events-list-grid,
  .tasting-menus-list-grid {
    margin-top: 74px;
    padding: 0 50px;
  }

  .tasting-menus-list-grid {
    padding-top: 20px;
  }

  .event-block,
  .tasting-menu-block {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;

    &.big {
      display: flex;
      margin-bottom: 80px;
      margin-top: 30px;
      padding: 35px 65px 35px 0;

      h2 {
        margin-top: 0;
        text-align: center;
      }

      .img-wrapper {
        height: initial;
        width: 376px;
        flex-shrink: 0;
        margin-top: -65px;
        margin-bottom: -65px;
        margin-right: 68px;
      }

      .text-wrapper {
        width: calc(100% - 376px - 68px);
      }

      .event-info {
        justify-content: center;
      }

    }

  }

  .event-info {
    margin-bottom: 32px;
    padding-bottom: 26px;
    font-size: rem(16px);
  }

}
