
.copy-input {
  position: relative;
  max-width: 700px;
  margin-bottom: 40px;

  button {
    @include reset-btn;

    position: absolute;
    z-index: 2;
    right: 0;
    top: 14px;
    color: $accent-color;
    font-weight: 700;
  }

  input {
    padding-right: 85px;
  }

  svg {
    top: 12px;
    width: 20px;
  }

}

.services .btn {
  display: block;
  margin-top: 20px;
}
