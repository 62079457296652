html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

a {
  color: inherit;
}

input,
select,
textarea,
button {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}


body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  font-size: $base-font-size;
  font-family: $base-font-family;
  line-height: 1.2;
}

hr {
  border-top: 1px solid $border-color;
}

small {
  font-size: rem(12px);

  &.bigger {
    font-size: rem(14px);
  }

}

.icon {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  color: $accent-color;
}

.sm-hidden {
  display: none;
}

.has-bg {
  background-image: url(../images/body.jpg);
  background-position: top 20px right;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .page-content {
    margin-top: 20px;
    padding: 26px 22px;
    background-color: $white;
  }

}

.page-content,
.text-content {
  line-height: 1.43;
}

.text-content p {
  margin-bottom: 20px;
}

.section {
  margin-bottom: 50px;
}

.section-title {
  margin-top: 0;
  margin-bottom: 22px;
  font-size: rem(24px);
  font-weight: 400;

  &.big {
    font-size: rem(36px);
  }

  &.small {
    font-size: rem(18px);
    font-weight: 500;
  }

  &.underlined {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 22px;
      height: 5px;
      background-color: $accent-color;
      border-radius: 5px;
    }

  }

  + .section-sub-title.small {
    margin-top: -10px;
  }

  + .section-sub-title {
    margin-top: -10px;
    margin-bottom: 20px;
  }

}

.section-sub-title {
  margin-top: 0;
  font-size: rem(16px);
  font-weight: 500;

  &.small {
    font-size: rem(12px);
    font-weight: 400;
    color: $lighter-text-color;
  }

}

.centered-text {
  text-align: center;
}

.section-paragraphs {
  margin-bottom: 20px;
  color: $lighter-text-color;
}

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;

  .section-sub-title {
    margin-bottom: 0;
  }

}

.align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.disabled-link {
  pointer-events: none;
}

.btn-link {
  @include reset-btn;

  color: $accent-color;
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    color: $lighter-text-color;
    cursor: not-allowed;
  }

}

.clickable {
  cursor: pointer;
}

/**
 * Animation for react-transition-group
 */
.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

@include large {

  .lg-hidden {
    display: none;
  }

  .sm-hidden {
    display: block;
  }

  .has-bg {
    background-position: top -250px center;

    .page-content {
      padding: 56px 50px;
    }

  }

  .section-paragraphs {
    margin-bottom: 0;
  }

}
