.verify-section-wrapper {
  margin-bottom: 40px;

  img {
    width: 100%;
  }


}

.verify-section {

  .section-title {
    margin-bottom: 10px;
  }

  .btn {
    margin-left: 0;
    margin-top: 50px;
  }

}

.btn-reload-checklist {
  margin-bottom: 29px;
  font-weight: 500;

  .icon {
    margin-right: 5px;
  }

  &.disabled-link {
    color: $lighter-text-color;
    .icon  {
      color: $lighter-text-color;
    }
  }

}

.verify-steps {

  .step {
    justify-content: space-between;
    margin-bottom: 9px;
    padding-left: 45px;
    font-size: rem(20px);

    &::before {
      left: 0;
    }

    &::after {
      left: 5px;
    }

  }

  .external-link {
    flex-shrink: 0;
    text-align: left;
    font-size: rem(16px);
  }

  &::before {
    left: 15px;
  }

}

@include large {

  .verify-section-wrapper {
    display: flex;
    align-items: center;

    .img-wrapper {
      flex-shrink: 0;
      width: 31%;
      margin-right: 92px;
    }

  }

  .verify-steps .external-link {
    margin-left: 10px;
  }

}
