.radio {
  align-items: center;

  input:checked+.box::after {
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    background-color: $accent-color;
    border-radius: 50%;
  }

  .box {
    border-radius: 50%;
  }

}

.radio-label {
  flex-shrink: 0;
  display: block;
  min-width: 72px;
  margin-left: 18px;
  padding: 6px 14px;
  border-radius: 3px;
  background-color: $dark-gray;
  color: $white;
  font-size: rem(11px);
  text-align: center;
}

.big-radio {
  padding: 15px 16px;
  border: 1px solid $accent-color;

  .radio {
    margin-bottom: 0;
  }

  .box {
    margin-top: 0;
  }

  .text {
    width: 100%;
  }

}
