.restaurant-title {
  margin-top: 0;
  padding-right: 75px;
  font-size: rem(24px);
  font-weight: normal;
  line-height: 1.08;
}

.restaurant-info-wrapper {
  position: relative;
  margin-bottom: 50px;
}

.favorite-restaurant-wrapper.tooltip-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.favorite-restaurant {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: $white;
  border: 0;
  cursor: pointer;
  outline: none;

  .icon {
    width: 50px;
    height: 50px;
  }

  &.active {
    background-color: $accent-color;
    
    .icon {
      color: $white;
    }

  }

}

.restaurant-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 8px;
}

.info-item {
  display: flex;
  align-items: flex-start;
  width: 49%;
  margin-bottom: 22px;

  h3 {
    margin: 0;
    font-weight: 500;
  }

  p {
    margin: 4px 0 0;
  }

  .icon {
    flex-shrink: 0;
    width: 25px;
    height: auto;
    margin-top: -4px;
    color: $accent-color;
  }

}

.cuisines span {

  &::after {
    content: ',';
    margin-right: 4px;
  }

  &:last-child::after {
    display: none;
  }

}

.working-hours {
  display: block;
}

@include large {

  .restaurant-info-wrapper {
    margin-bottom: 0;
  }
  
  .info-item {
    width: 47%;
  }

}
