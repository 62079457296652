.menu-items-section {
  margin-top: 30px;

  .form-group {
    background-color: transparent;
  }

}

.menu-item-group {
  position: relative;
  padding-right: 24px;

  &:nth-child(odd) {
    background-color: $gray;
  }

  &:first-child:last-child {
    padding-right: 0;

    .delete-btn {
      opacity: 0;
    }

    &::after {
      display: none;
    }

  }

  .delete-btn {
    opacity: 1;
  }

}

.add-another-btn {
  margin-top: 30px;
  margin-bottom: 30px;
}

.grid-group {

  + .grid-group {
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid $border-color;
  }

}

.form-group {

  .rc-time-picker {
    width: 100%;
  }

  .rc-time-picker-input {
    padding-left: 0;
    border-radius: 0;
    font-size: inherit;
  }

  .rc-time-picker-clear {
    top: 12px;
  }

}

.time-picker-custom.rc-time-picker-panel {

  &.rc-time-picker-panel-narrow {
    max-width: 100%;
  }

  .rc-time-picker-panel-inner {
    font-size: rem(14px);
    border-radius: 0;
    box-shadow: $box-shadow;
    border: 0;
  }

  .rc-time-picker-panel-input-wrap {
    padding: 15px 10px 10px;
    border-color: $primary-color;
  }

  .rc-time-picker-panel-select {
    width: 85px;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-gray;
      border-radius: 20px;
    }

  }

  li.rc-time-picker-panel-select-option-selected {
    background: $accent-color;
    color: $white;
  }

}

.event-block.big,
.tasting-menu-block.big {
  margin-bottom: 140px;
}

.multiple-buttons .btn {
  margin-bottom: 20px;
  width: 100%;

  // &:last-child {
  //   margin-bottom: 0;
  // }

}

.invertible-grid {

  textarea {
    background-color: $gray;
  }

  &.inverted {
    display: grid;

    .grid-col-5:nth-child(odd) {
      order: 1;
    }

  }

}

.past-events-grid {
  background-color: white;

  img {
    mix-blend-mode: luminosity;
    filter: grayscale(100%);
  }
  
  .icon {
    color: $dark-gray;
  }

  .btn {
    background-color: $dark-gray;
    border-color: $dark-gray;
  }

  .add-img.edit-img {
    background-color: $dark-gray;
  }

}

.invertible-fields.inverted {
  display: grid;

  .invertible-field:nth-child(odd) {
    order: 1;
  }

}

@include medium {

  .multiple-buttons {
    @include clearfix;

    .btn {
      float: left;
      margin-right: 20px;
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
    }

    .right {
      float: right;
      margin-right: 0;
    }

  }

}

@include min-width(1280px) {

  .multiple-buttons .btn {
    padding-left: 40px;
    padding-right: 40px;
  }

}
