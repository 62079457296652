.benefits {
  margin-top: 24px;
  padding-left: 0;
  list-style: none;
  font-size: rem(12px);

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .icon {
    margin-right: 15px;
    color: $accent-color;
  }

}


.awards-block {
  padding: 15px;
  background: url(../../assets/images/pattern.png) repeat center $awards-color;
  color: $white;

  .benefits {
    margin: 0;
    font-size: rem(14px);

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }

    }

    .icon {
      width: 16px;
      height: auto;
      margin-right: 6px;
      color: $white;
    }

  }

}
