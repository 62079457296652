.dd-box {
  font-weight: normal;
  font-size: rem(16px);
}

@include larger {
  
  .dd-box {
    position: absolute;
    top: calc(100% + 22px);
    left: 0;
    min-width: 167px;
    background-color: $white;
    border: 1px solid $header-border;

    li {
      display: block;
      float: none;
      margin: 0 10px;
    }

    a.active, a:hover {
      text-shadow: 0 0 $primary-color, 0 0 1px $primary-color, 1px 0 $primary-color, 0 1px $primary-color;
    }

  }

}
