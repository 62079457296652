.date-picker {
  position: relative;
  height: 46px;
  outline: none;
  cursor: pointer;

  .date-result {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 0;
  }

  .pick-date {
    position: absolute;
    z-index: 3;
    left: -14px;
    width: 100vw;
    top: 100%;
    margin-top: 1px;
    padding-top: 20px;
    background: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: auto;
    height: 40px;
    transform: translateY(-50%);
  }

}

.booking-header .date-picker {
  padding-right: 25px;
  font-weight: 700;
}

.date-result {

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 10px;
    background: url(../../react-web-ui/assets/images/icons/ArrowDown.svg) no-repeat center/100%;
  }

  &.is-open:after {
    transform: translateY(-50%) rotate(180deg);
  }

}

.dates-info {
  display: none;
  padding: 0 20px;
}

@include small {

  .date-picker {

    .pick-date {
      left: 0;
      width: auto;
    }

  }

  .booking-header .date-result {
    padding-left: 40px;
    font-size: rem(16px);
  }

}
