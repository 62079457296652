.btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 40px;
  border-style: solid;
  border-width: 2px;
  color: $white;
  font-weight: bold;
  font-size: rem(16px);
  text-align: center;
  cursor: pointer;
  outline-color: transparent;
  text-transform: uppercase;
  text-decoration: none;

  .icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  &[disabled] {
    background: $light-gray;
    border-color: $light-gray;
    color: $white;
    cursor: not-allowed;
  }

}

.btn-full {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@include large {

  .btn {
    display: table;
  }

  .btn-full {
    display: block;
    width: 100%;
  }

}
