.pill-label {
  display: block;
  width: fit-content;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: $accent-color;
  color: $white;
  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }

}

.show-more-arrow {
  color: $dark-gray;

  svg {
    transform: rotate(-90deg);
  }

}

.bookings-table,
.payments-table {
  width: 100%;
  overflow: auto;
}

.reservations-table {
  margin-bottom: 30px;
}

.relative {
  position: relative;
}

.reservations-table,
.bookings-table table,
.payments-table table {
  width: 100%;
  border: 1px solid $border-color;
  border-collapse: collapse;
  font-size: rem(12px);

  th,
  td {
    height: 40px;
    padding: 5px;
  }

  tbody>tr:nth-child(odd) {
    background-color: $gray;
  }

  tbody>tr:not(.selected):hover {
    background-color: $accent-color-hover;
  }

  thead {
    text-align: left;
    color: $lighter-text-color;

    tr {
      border-bottom: 2px solid $border-color;
    }

  }

}

.payments-table table {
  text-align: center;

  th {
    text-align: center;
  }

  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    text-align: left;
  }

}

.bookings-table table {
  min-width: 522px;

  th {
    white-space: nowrap;
  }

  td:last-child {
    text-align: center;
  }

  td:nth-child(3) {
    white-space: nowrap;
  }

  tbody {

    tr {
      cursor: pointer;
    }

    tr.selected {
      background-color: $accent-color;
      color: $white;

      .pill-label {
        background-color: $white;
        color: $accent-color;
      }

      .show-more-arrow {
        color: $white;
      }

    }

  }

  .sort {
    @include reset-btn;

    position: relative;
    cursor: pointer;
    padding-right: 20px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      opacity: 0.4;
    }

    &::after {
      margin-top: -6px;
      border-width: 0 4px 4px 4px;
      border-color: transparent transparent #000000 transparent;
    }

    &::before {
      margin-bottom: -6px;
      border-width: 4px 4px 0 4px;
      border-color: #000000 transparent transparent transparent;
    }

  }

  .asc::after {
    opacity: 1;
  }

  .desc::before {
    opacity: 1;
  }

}

.info-wrapper~.page-content {
  padding-top: 45px;
}

@include max-width(420px) {

  .bookings-table table .sort {
    padding-right: 12px;
    font-size: rem(11px);
  }

  .reservations-table th,
  .reservations-table td,
  .bookings-table table th,
  .bookings-table table td {
    height: 30px;
    padding: 3px;
  }

}

@include large {

  .reservations-table,
  .bookings-table table {
    padding: 5px 20px;
  }

  .reservations-table-wrapper {
    position: sticky;
    top: 49px;
    max-height: calc(100vh - 49px);
    overflow: auto;
  }

}
