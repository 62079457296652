.chef-name {

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none;
  }

  .icon {
    width: 20px;
    margin-left: 15px;
    color: $white;
  }

}

.chef-img-wrapper {

  .add-img {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -14px;
    padding: 5px;
    background-color: $accent-color;
  }

  .icon {
    color: $white;
    width: 18px;
    height: 18px;
  }

}

@include large {

  .chef-img-wrapper {

    .add-img {
      margin-top: -20px;
      padding: 5px 10px;
      background-color: $accent-color;
    }

    .icon {
      width: 30px;
      height: 30px;
    }

  }
  
}
