.event-block .add-img,
.special-offer-block .add-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 16px;

  &.edit-img {
    bottom: initial;
    right: initial;
    padding: 5px 10px;
    background-color: $accent-color;
    border-radius: 0;

    .icon {
      width: 30px;
      height: 30px;
      color: $white;
    }

  }

}
