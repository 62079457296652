.reviews-wrapper {
  margin-top: 26px;
}

.circles-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 340px;
  margin-top: 20px;
  text-align: center;
}

.circle {
  padding: 0;
}
