.pick-date-input {
  position: relative;
  height: auto;

  .DateInput_input__focused {
    border-color: $accent-color;
  }

  .btn {
    max-width: 100%;
    width: 284px;
    margin-top: 0;
  }

  .DayPicker_weekHeader_li {
    margin: 0;
  }

  .error {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
  }

}

.stats-columns .col {
  margin-bottom: 30px;
}

.chartjs-render-monitor {
  max-width: 100%;
}

.stats-block {
  margin-bottom: 20px;
  padding: 20px 125px 20px 20px;
  border-radius: 16px;
  background-color: $gray;
  font-weight: bold;

  h3 {
    font-weight: normal;
    margin-top: 0;
  }

  p {
    margin: 0;
    font-size: rem(26px);
  }

}

.giftcard-stats {
  background-image: url(../../react-web-ui/assets/images/default.png);
}

.tasting-stats {
  background-image: url(../../react-web-ui/assets/images/tasting.png);
}

.event-stats {
  background-image: url(../../react-web-ui/assets/images/event.png);
}

.giftcard-stats,
.tasting-stats,
.event-stats {
  background-repeat: no-repeat;
  background-position: top -40px right -40px;
  background-size: auto 160px;
}

@include large {

  .pick-date-input {
    display: flex;
    align-items: center;

    .btn {
      width: auto;
    }

  }

  .number-stats-block {
    display: flex;
    justify-content: space-between;
  }

  .stats-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 31%;
    padding: 130px 10px 10px;
  }

}