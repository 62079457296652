.thumbs-container {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  padding-right: 60px;
}

.gallery-thumbs {
  margin: 0 -5px;

  img {
    display: block;
    max-width: 100%;
    border: 4px solid transparent;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    max-width: 200px;
    height: auto;
    padding: 0 5px;
    cursor: pointer;

    .no-img {
      width: 100%;
      height: 100%;
    }

    > div,
    > div > div {
      height: 100%;
    }

  }

  .no-img {
    position: relative;
    display: block;
    width: 200px;
    height: 120px;
    background-color: $gray;
  }

  .slick-current img {
    border-color: $accent-color;
  }

}

.thumb-item {
  position: relative;
  outline: none;
}

.remove-slide-btn, .add-img-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.add-img-btn {
  display: flex;
  align-items: center;
  bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: rem(24px);
}

.add-thumb-btn {
  padding: 18px;
  color: $accent-color;
  border: 0;
}

.remove-slide-btn {
  width: 20px;
  height: 20px;
  padding: 0;
}

.delete-img {
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 20;
  margin: 0 auto;
  cursor: pointer;
  transform: translateX(-50%);
}

.restaurant-images .no-img {
  position: relative;
  height: 314px;
  width: 100%;
  background-color: $gray;
}

.add-img-button {
  padding-top: 30px;
}

.btn-reorder-images {
  display: block;
  margin: 10px auto 0;
  text-transform: uppercase;
  font-weight: 700;
}

.reorder-btns-container {
  display: flex;
  justify-content: center;

  .btn {
    margin: 10px;
  }

}

.reorder-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  row-gap: 5px;
  column-gap: 5px;

  img {
    display: block;
    max-height: 106px;
    width: 100%;
    object-fit: cover;
    margin: 15px auto;
    cursor: pointer;
  }

}

.sortable-helper {
  z-index: 20;
}

@include large {

  .restaurant-images .no-img {
    height: 426px;
  }

  .reorder-images {
    grid-template-columns: repeat(4, 1fr);
  }

}
