.collapsible-section {
  margin-bottom: 20px;

  .collapsible-section-content {
    padding: 20px 10px;
    border: 1px solid $border-color;
    border-top: none;
    margin-bottom: 0;
  }

  .collapsible-section-footer {
    border: 1px solid $border-color;
    margin-top: 0;
    padding: 10px 10px;
    text-align: center;

    button.delete {
      @include reset-btn;
      font-size: 14px;
      color: var(--error-color);
      border-bottom: 1px solid var(--error-color);
      text-transform: lowercase;
    }
  }

  .collapsible-section-content table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: center;
      padding: 4px;

      &:first-child {
        text-align: left;
        padding-right: 10px;
      }

      &:last-child {
        width: 20px;
        text-align: right;
      }

    }

    th {
      padding-bottom: 10px;
      border-bottom: 1px solid $gray;
    }

    tr:nth-child(even) {
      background-color: $gray;
    }

    button {
      padding: 8px 4px;
    }

  }

  .collapsible-section-sub-title {
    display: flex;
    border: 1px solid $border-color;
    margin-bottom: 0;
    padding: 10px;
    justify-content: space-between;

    button.toggle-visibility {
      border: none;
      font-weight: 600;
      font-size: 16px;
      background: none;

      .arrow {
        transform: rotate(90deg);
        display: inline-block;
        margin-right: 5px;
      }

      &:focus {
        outline: none;
      }
    }

    button.save {
      @include reset-btn;
      font-size: 16px;
      text-transform: lowercase;
      color: var(--accent-color);
      border-bottom: 1px solid var(--accent-color);
    }
  }

  &.collapsed {
    .collapsible-section-content,
    .collapsible-section-footer
    {
      display: none;
    }

    button.toggle-visibility {
      .arrow {
        transform: rotate(0deg);
      }
    }
  }
}
