.restaurant-menu-placeholder {
  position: relative;

  .grid {
    position: relative;
    padding-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(#fff, 0.4), $white 78%);
    }

  }

  .external-link {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
  }

}
