button.add-new-shadow {
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  .icon-plus {
    display: block;
    width: 84px;
    height: 84px;
    margin: 20px auto 0;
    color: $accent-color;
  }

}
