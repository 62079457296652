.contact-block {

  p {
    margin: 0;
  }

  .col {
    margin-bottom: 20px;
    border: 1px solid $border-color;
  }

}

.contact-info-block {
  position: relative;
  padding: 10px 10px 10px 50px;

  h3 {
    margin-bottom: 5px;
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 30px;
    width: 30px;
    height: 30px;
    margin-top: -12px;
  }
}

#contact-map {
  width: 100%;
  height: 250px;
}

#googlemap {
  width: 100%;
  height: 100%;

  * {
    overflow:visible;
  }

}

.bordered-info-block {
  margin-bottom: 20px;
  border-bottom: 1px solid $border-color;

  h3 {
    margin-top: 0;
    font-size: rem(16px);
    font-weight: normal;
  }

}


@include large {

  .column-layout {
    display: flex;
    justify-content: space-between;
  }

  .main-column {
    width: calc(100% - 420px);
  }

  .right-column {
    width: 380px;
  }

  .contact-block {
    padding: 20px;
  }

  .bordered-info-block {
    border: 1px solid $border-color;
  }

}
