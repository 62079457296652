.form-group {
  margin-bottom: 10px;
  background-color: $white;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input,
  select,
  textarea {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $primary-color;
    background-color: transparent;
    outline: none;

    &[disabled] {
      background-color: $gray;

      + svg {
        z-index: 1;
      }

    }

  }

  input,
  select {
    height: 46px;
  }

  select {
    padding-right: 28px;
  }

  textarea {
    height: 110px;
    padding-top: 10px;
    resize: none;
  }

  svg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 8px;
    width: 30px;
    height: auto;
  }

  .date-picker {
    border-bottom: 1px solid $primary-color;
  }

  .select-item {
    color: $primary-color;

    &.selected {
      background-color: $accent-color;
      color: $white;
    }

  }

  &.error {

    input,
    select,
    textarea {
      border-color: $error-color;
    }

    p {
      margin: 0;
    }

  }


}

.has-icon {

  input,
  select,
  textarea {
    padding-left: 34px;
  }

}

p.error,
p.success {
  font-size: rem(12px);
}

p.error,
p.success,
h3.error,
h3.success {
  margin-top: 0;
}

p.error,
h3.error {
  color: $error-color !important;
}


p.success,
h3.success {
  color: $success-color !important;
}

.input-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
}

.checkbox,
.radio {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  font-size: rem(15px);
  cursor: pointer;

  input {
    // display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    // right: 100%;
    // top: 100%;

    &:checked+.box::after {
      content: '';
      position: absolute;
    }

  }

  .text {
    display: block;
  }

  .box {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-right: 12px;
    background-color: $checkbox-bg;
  }

}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: url(../../assets/images/icons/ArrowDown.svg) no-repeat center right;
}

.rmsc.multi-select {

  .dropdown-container {
    border: none;
    border-bottom: 1px solid $primary-color;
    border-radius: 0;

    .panel-content {
      border-radius: 0;
    }

    input[type=checkbox] {
      display: none;
    }

    .dropdown-heading-dropdown-arrow  {
      color: $primary-color;

      svg {
        width: 30px;
        height: auto;
        margin-right: -5px;
      }

    }

    &:focus-within {
      box-shadow: none;
      border-color: $primary-color;
    }

  }

  .dropdown-heading {
    height: 46px;
    padding: 0;
  }

  .dropdown-content {
    z-index: 2;
    color: $accent-color;
  }

}