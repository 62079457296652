.page-footer {
  margin-top: 30px;
  background-color: $white;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: block;
  }

  a {
    display: block;
    padding: 5px 0;
  }

}

.grid-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-top {
  padding: 26px 0;
  background-color: $gray;
  color: $primary-color;

  h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: rem(20px);
  }

  li {
    margin-bottom: 5px;
  }

  .grid>div {
    margin-bottom: 40px;
  }

}

.footer-illustration {
  display: none;
  margin-top: -70px;
  max-width: 100%;
}

.footer-bottom {
  position: relative;
  z-index: 100;
  background-color: $accent-color;
  color: $white;

  a {
    text-transform: uppercase;
    text-decoration: none;
  }

  p {
    text-align: right;
  }

}

.footer-bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pfr-banner {
  display: block;
  max-width: 600px;
  margin: 10px auto 0;
  padding-bottom: 10px;
}

@include medium {

  .footer-top .grid {
    display: flex;
    justify-content: space-between;

    >div:first-child {
      flex-shrink: 0;
      margin-right: 40px;
    }

  }

}

@include min-width(960px) {

  .footer-bottom li {
    display: inline-block;
    margin: 4px 25px 4px 0;
  }

}

@include large {

  .footer-top {
    margin-top: 100px;

    .grid {
      display: grid;
    }

  }

  .footer-illustration {
    display: block;
  }

}