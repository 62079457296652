.tour-categories {
  min-height: 200px;
}

@include max-width(1024px) {

  .category-name-grid .grid-col-5 {
    display: inline-block;

    &:first-child h3::after {
      content: '/';
      display: inline-block;
      margin: 0 5px;
    }

  }

} 
