.shadow-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: $box-shadow;

  p {
    margin-top: 4px;
    margin-bottom: 0;
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding-right: 78px;

    span {
      margin-right: 10px;
    }

  }

  .additional-info {
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: none;
    }

  }

  .external-link {
    text-align: left;
  }

  .toggle-status {
    width: 100%;
    margin-bottom: 10px;
  }

}

.edit-shadow-btn,
.discard-shadow-btn,
.save-shadow-btn {
  @include reset-btn;

  position: absolute;
  z-index: 3;
}

.edit-shadow-btn,
.save-shadow-btn {
  top: 28px;
  right: 10px;
  display: flex;
  align-items: center;
  color: $accent-color;
  font-size: rem(13px);

  .icon {
    margin-right: 6px;
  }

}

.save-shadow-btn {
  right: 40px;
  padding-right: 10px;
  border-right: 1px solid $border-color;

  &:disabled {
    color: $lighter-text-color;

    .icon {
      color: inherit;
    }

  }

}

.discard-shadow-btn {
  top: 18px;
  right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  background-color: $accent-color;

  .icon {
    width: 30px;
    margin: -5px;
    color: $white;
  }

}

.success-ribbon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 2px;
  background-color: $discount-color;
  color: $white;
  font-size: rem(12px);
  text-align: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.toggle-status {
  @include reset-btn;

  position: relative;
  display: flex;
  width: 160px;
  min-height: 32px;
  background-color: $gray;
  font-weight: 500;
  color: $lighter-text-color;
  border-radius: 20px;

  span {
    position: relative;
    z-index: 2;
    width: 50%;
    padding: 8px 5px;
    transition: color 0.4s;
    line-height: 1;

    &:first-child {
      color: $white;
    }

  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    background-color: $accent-color;
    border-radius: 20px;
    transition: left 0.4s, right 0.4s;
  }

  &[disabled] {
    cursor: not-allowed;

    &::before {
      background-color: $dark-gray;
    }

  }

  &.active {

    span {

      &:first-child {
        color: inherit;
      }

      &:last-child {
        color: $white;
      }

    }

    &::before {
      right: 0;
      left: 50%;
    }

  }

}

@include medium {

  .shadow-block {
    margin-bottom: 0;
  }

}

@include large {

  .shadow-block {
    padding: 25px 30px;
  }

  .edit-shadow-btn,
  .discard-shadow-btn {
    right: 20px;
  }

  .discard-shadow-btn {
    top: 26px;
  }

  .save-shadow-btn {
    right: 55px;
  }

  .success-ribbon {
    padding: 5px;
  }

}
