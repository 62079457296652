.page-heading {
  margin-bottom: 50px;

  .btn {
    display: table;
    margin: 20px 0 0;
  }

}

.landing-img {
  max-width: 100%;
}

.sticky-buttons,
.action-buttons {
  display: table;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
  background-color: $white;

  .btn {
    display: inline-block;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

  }

}

.center-buttons {
  display: table;
  margin: 0 auto;
  text-align: center;

  .btn {
    display: inline-block;
    margin: 0 5px;
  }

}


.sticky-buttons {
  position: sticky;
  z-index: 3;
  top: 0;
}

.label {
  font-size: rem(18px);
}

.value-select.selected {
  background-color: $accent-color;
  color: $white;
}

.first-section {

  p {
    font-size: rem(18px);
  }

}

.text-center {
  text-align: center;
}

.feature-block {
  margin-top: 30px;
  padding: 30px 60px;
  background-color: $gray;

  p {
    margin: 0;
  }

}

.pricing-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .pricing-option:last-child {
    border-bottom: none;
  }

}

.pricing-block-column,
.pricing-table {
  margin-bottom: 40px;

  h3 {
    margin: 0;
    font-size: rem(24px);
    text-transform: uppercase;
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }

}

.pricing-header {
  padding: 18px 10px;
  background-color: $accent-color;
  color: $white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  text-align: center;
  vertical-align: top;

  .price-label {
    margin-bottom: -10px;
  }

}

.price-label {
  background-color: $white;
  color: $accent-color;
  padding: 2px;
  font-weight: normal;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.pricing-block-column {
  width: 100%;
  margin: 30px auto 0;
  padding-bottom: 20px;
  border-radius: 16px;
  box-shadow: $box-shadow;
}

.pricing-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid $border-color;
  font-size: rem(16px);

  .icon {
    flex-shrink: 0;
  }

}

.partner-logos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  row-gap: 40px;
  column-gap: 30px;
}

.logo-wrapper {
  position: relative;
  height: 148px;

  img {
    display: block;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .img-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s;
  }

  .change {
    transform: scale(0);
  }

}

.more-logos {
  font-weight: bold;
  font-size: rem(20px);
  text-align: center;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 148px;
    height: 148px;
    margin: 0 auto;
    padding: 10px;
    background-color: $gray;
  }

}

.show-tips {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 20;
}

.btn-show-tips {
  width: 30px;
  height: 30px;
  padding: 2px 5px;
  font-size: rem(18px);
  background-color: $dark-gray;
  border: none;
  border-radius: 50%;
}

.content-loader svg {
  width: 100%;
  height: auto;
}

.with-anchors {
  position: relative;
}

.anchors {
  display: none;
}

.text-page-content h2 {
  padding-top: 60px;
  margin-top: -60px;
}

@include large {

  .sticky-buttons {
    margin-bottom: -30px;
  }

  .page-heading h1 {
    padding-right: 250px;
  }

  .feature-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .feature-block {
    width: 32%;
    font-size: rem(20px);
  }

  .pricing-content {
    width: 220px;
    font-size: rem(18px);

    // p {
    //   display: none;
    // }

  }

  td.pricing-option {
    display: table-cell;
    padding: 10px 5px;
  }


  .pricing-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;

    tr {

      &:hover td {
        background-color: $gray;
      }

      &:last-child td {
        border-bottom: 1px solid $border-color;
      }

    }

    td {
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;

      &:first-child {
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        border-left: 1px solid $border-color;
      }

    }

    .pricing-header {
      width: 220px;
    }

    .pricing-option {
      height: 60px;
    }

    .btn {
      margin-top: 20px;
      margin-bottom: 20px;
    }

  }

  .more-logos {
    font-size: rem(24px);
  }

}

@media only screen and (min-width: 1310px) {
  .anchors {
    display: block;
    position: sticky;
    top: 0;
    float: left;
    width: 236px;
    padding-right: 20px;
    padding-top: 60px;
    border-right: 1px solid var(--border-color);
    font-size: 1.5rem;
    font-weight: 700;
  }
  .anchors a {
    display: block;
    text-decoration: none;
    margin-bottom: 25px;
  }
  .anchors .selected {
    color: var(--accent-color);
  }
}
