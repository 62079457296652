.pill-btn {
  @include reset-btn;

  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 30px;
  border: 1px solid $border-color;
  background-color: $white;

  &.active {
    color: $white;
    background-color: $accent-color;
    font-weight: bold;
  }

}
