.clear-both {
  clear: both;
}

.reset-btn {
  @include reset-btn;
}

.external-link {
  display: block;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  
  .icon {
    width: 16px;
    margin-right: 8px;
  }

}

.grid {
  position: relative;
}

.main-column {
  margin-bottom: 20px;
}

.global-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 63px 0 0;

}

// .page-content {
//   padding-top: 20px;
// }

@include larger {

  .global-wrapper {
    padding-top: 85px;
  }

}
