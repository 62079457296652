.restaurant-block {
  position: relative;
  display: block;
  margin-top: 50px;
  padding: 1px 26px 87px;
  text-decoration: none;
  box-shadow: $box-shadow;
  border-radius: 16px;
  background-color: $gray;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    overflow: hidden;
  }

  h2 {
    margin-top: 14px;
    margin-bottom: 15px;
    font-size: rem(24px);
    font-weight: 500;

    a {
      display: block;
      text-decoration: none;
    }

  }

  p {
    display: flex;
    align-items: flex-start;
    margin-bottom: 4px;
    margin-top: 0;
    font-size: rem(12px);
    font-weight: 500;
    color: $lighter-text-color;

    .icon {
      width: auto;
      height: 25px;
      margin-right: 5px;
      flex-shrink: 0;
      margin-top: -6px;
    }

  }

  .img-wrapper {
    position: relative;
    display: block;
    min-height: 150px;
    margin-top: -50px;
  }

  .btn {
    position: absolute;
    display: block;
    left: 26px;
    right: 26px;
    bottom: 24px;
    width: calc(100% - 26px - 26px);
    padding-left: 10px;
    padding-right: 10px;
  }

  .star-ratings {
    margin-top: 12px;
  }

  .icon-plus {
    display: block;
    width: 126px;
    margin: 25px auto 0;
  }

  &.open-create {
    padding-top: 30px;
    background-color: $white;
  }

  &:hover,
  &.active {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
  }

}

.ribbon {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  padding: 8px 14px;
  background-image: linear-gradient(to right, $accent-color 20%, rgba(107, 27, 27, 0) 100%);
  color: $white;
  font-size: rem(16px);
  font-weight: 700;
  text-transform: uppercase;
}

.restaurant-with-map .restaurant-block,
.horizontal-block {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding: 0;

  img {
    height: 100%;
    object-fit: cover;
  }

  h2 {
    margin-bottom: 10px;
    font-size: rem(20px);
  }

  .img-wrapper {
    width: 112px;
    margin-top: -25px;
  }

  .ribbon {
    font-size: rem(12px);
  }

  .star-ratings {
    margin-top: 5px;
  }

  .text-wrapper {
    width: calc(100% - 110px - 15px);
    margin-bottom: 13px;
    padding-right: 15px;
  }

  .btn {
    position: static;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

}

@include small {

  .restaurant-with-map .restaurant-block,
  .horizontal-block {

    .img-wrapper {
      width: 147px;
    }

    .text-wrapper {
      width: calc(100% - 147px - 15px);
    }

  }

}
