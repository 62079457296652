.form-group {
  margin-bottom: 18px;

  label {
    margin-bottom: 0;
    color: $lighter-text-color;
    font-size: rem(12px);
  }  

  input,
  select,
  .dropdown-heading {
    height: 36px;
  }

  svg {
    top: 3px;
  }

}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  height: 400px;
  margin-top: -80px;
  margin-bottom: 40px;
  padding-top: 80px;

  > img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .inner-wrapper,
  .app-preview {
    width: 100%;
  }

  &.small {
    min-height: 240px;
    height: 240px;
  }

}

.hero-section-content {
  flex-shrink: 0;
  width: 100%;
  max-width: 548px;
  padding: 30px;
  background-color: $white;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);

  h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: rem(24px);
  }

  p {
    margin-top: 0;
    font-size: rem(20px);
  }

}

.alternating-blocks {
  margin-bottom: 40px;

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  h2 {
    margin: 0;
    font-size: rem(36px);
  }

  h3 {
    margin: 0;
    font-weight: normal;
    font-size: rem(30px);
  }

  p {
    font-size: rem(16px);
  }

  .img-wrapper {
    margin: 0 auto 40px;
  }

  .with-circle {
    position: relative;
    height: 0;
    margin-bottom: 10px;
    padding-bottom: 100%;
    overflow: hidden;

    img {
      width: 100%;
    }

  }

}

.c-shape-wrapper {
  position: absolute;
  z-index: -1;
  top: -34px;
  left: -34px;
  right: -34px;
  width: calc(100% + 34px + 34px);

  img {
    display: block;
    width: 100%;
  }

}

.illustration {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
  margin: 0 auto;
}

.btn-show-additional {
  display: block;
  width: 25px;
  height: 25px;
  padding: 4px;
  font-weight: bold;
  font-size: rem(16px);
  background-color: $gray;
  border: none;
  border-radius: 50%;
  color: $accent-color;
  text-align: center;
}

.compare-pricings {
  margin-top: 40px;
}

.compare-row {
  margin-top: 20px;
  padding: 15px 10px 0;
  border: 1px solid $border-color;

  h3,
  p {
    margin: 0;
  }

  header,
  .pricing-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  header {
    padding-bottom: 10px;
    font-size: rem(16px);
    border-bottom: 1px solid $border-color;
  }

  h3 {
    font-size: rem(16px);
    text-transform: uppercase;
  }

  .pricing-option:last-child {
    border-bottom: none;
  }

  .tooltip-wrapper {
    margin-left: 20px;
  }

}

.btn-show-all-features {
  display: block;
  margin-bottom: 40px;
  width: 100%;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.pricing-period {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .icon {
    margin-right: 10px;
  }

  .toggle-status {
    width: 200px;
  }

}

.progress-section {
  margin-bottom: 40px;
}

.vertical-progress {
  position: relative;

  small {
    flex-shrink: 0;
    display: inline-block;
    padding: 2px 10px;
    font-size: rem(10px);
    font-weight: normal;
    color: $white;
    background-color: $accent-color;
    border-radius: 10px;
    font-size: rem(14px);
  }

  .step {
    position: relative;
    display: block;
    margin-bottom: 25px;
    padding-left: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: rem(24px);

    &.active {
      font-weight: bold;
      color: $accent-color;

      &::after {
        display: block;
      }

    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      left: 9px;
      width: 34px;
      height: 34px;
      background-color: $gray;
    }

    &::after {
      display: none;
      left: 14px;
      width: 24px;
      height: 24px;
      background-color: $accent-color;
    }

    &:first-child {
      padding-top: 0;

      &::before {
        top: 0;
      }

      &::after {
        top: 5px;
      }

    }

  }

  &::before {
    content: '';
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 24px;
    width: 4px;
    background-color: $gray;
  }

}

.open-login {
  display: block;
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: rem(14px);
  font-weight: bold;
}

.join-form {

  .input-wrapper {
    font-weight: bold;
    font-size: rem(16px);
  }

  .checkbox,
  .radio {
    margin-bottom: 22px;
  }

}

.restricted-content {
  max-width: 500px;
  margin: 0 auto;

  img {
    max-width: 100%;
    height: auto;
  }

  .hero-section-content {
    flex-shrink: 1;
  }

}

@include medium {

  .alternating-blocks {

    .img-wrapper {
      width: 506px;
    }

    .with-circle {
      padding-bottom: 506px;
    }

  }

  .restricted-content {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;

    img {
      max-width: 50%;
    }

  }

}

@include large {

  .hero-section-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hero-section {
    margin-top: -138px;
    padding-top: 138px;
    height: 600px;
  }

  .hero-section-content .btn {
    margin-left: 0;
  }

  .alternating-blocks-wrapper {

    .alternating-blocks:nth-child(odd) {

      .img-wrapper {
        order: 1;
      }

      .c-shape-wrapper {

        img {
          animation: rotating 16s linear infinite;
        }

      }

    }


  }

  .alternating-blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: rem(42px);
    }

    h3 {
      font-size: rem(38px);
    }

    p {
      font-size: rem(20px);
    }

    .img-wrapper {
      flex-shrink: 0;
      margin: 0;
    }

    .text-wrapper {
      width: calc(100% - 600px);
    }

  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotatingCounter {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  .c-shape-wrapper {
    top: -74px;
    left: -74px;
    right: -74px;
    width: calc(100% + 74px + 74px);
    
    img {
      animation: rotatingCounter 16s linear infinite;
    }

  }

  .restricted-content .hero-section-content {
    width: 50%;
    margin-left: -165px;
  }

  .progress-section {
    padding-right: 60px;
  }

  .vertical-progress {

    small {
      margin-left: 20px;
    }

    .step {
      display: flex;
      align-items: center;
    }

  }

}

@include min-width(1640px) {

  .alternating-blocks .img-outside {
    margin-left: -166px;
    width: 645px;
  }

}
