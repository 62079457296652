.salon-statuses {
  display: flex;
}

.seating-status {
  display: inline-block;
  margin-right: 10px;
  padding: 4px 10px;
  background-color: $accent-color;
  color: $white;
  border-radius: 15px;

  &.inactive {
    background-color: $light-gray;
  }

  &.outside {
    background-color: $discount-color;
  }

  &.inside {
    background-color: $event-color;
  }

}

.hide-header {

  .info-wrapper,
  .page-header {
    display: none;
  }

}

.floorplan-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color;

  .buttons {
    display: flex;
    align-items: center;
  }

  .btn {
    height: 60px;
  }

}

.selected-salon-wrapper {
  position: relative;
}

.selected-salon {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 15px;
  background-color: $accent-color;
  color: $white;
  font-weight: bold;

  h2 {
    margin: 0;
    font-size: rem(24px);
  }

  .arrow {
    @include reset-btn;

    width: 28px;
    height: 28px;
    margin-left: 15px;
    padding: 7px 5px;
    background-color: $white;
    color: $accent-color;
    border-radius: 3px;
    cursor: pointer;

    &.rotate svg {
      transform: rotate(180deg);
    }

  }

}

.other-salons {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 250px;
  background-color: $gray;
  font-size: rem(18px);
  font-weight: bold;
  box-shadow: $box-shadow;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    border-bottom: 2px solid $white;
  }

  li:last-child a {
    border-bottom: none;
  }

}

.btn-discard {
  margin-left: 20px;
  margin-right: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.floorplan-top,
.full-width-inner-wrapper {
  max-width: 2000px;
  margin: 0 auto;
}

.full-width-inner-wrapper {
  padding: 0 20px;
}

.floorplan-content {
  display: flex;
  justify-content: space-between;
}

.left-floorplan-column,
.center-floorplan-column,
.right-floorplan-column {
  border: 1px solid $border-color;
  height: calc(100vh - 100px);
  min-height: 600px;

  h2 {
    margin: 0;
    font-size: rem(14px);
  }

}

.column-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  margin: 0;
  padding: 10px 20px;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  &:first-child {
    border-top: 0;
  }

}

.zoom-scale {
  display: inline-block;
  margin-left: 5px;
  min-width: 40px;
}

.left-floorplan-column {
  width: 224px;

  .some-element {
    margin-top: 20px;
  }

}

.current-elements {
  display: flex;
  align-items: center;

  .tooltip-wrapper {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    padding: 3px;
    border-radius: 50%;
    background-color: $gray;
    text-align: center;
  }

}

.center-floorplan-column {
  width: calc(100% - 224px - 273px - 40px);
}

.canvas-container {
  position: relative;
  width: 100%;
  height: calc(100% - 48px);
  background-image: linear-gradient(transparent 11px, $border-color 12px, transparent 12px), linear-gradient(90deg, transparent 11px, $border-color 12px, transparent 12px);
  background-size: 100% 12px, 12px 100%;
  overflow: auto;
}

.floorplan-grid {
  position: absolute;
  width: 100%;
  height: 100%;
}

.right-floorplan-column {
  width: 273px;
  overflow: auto;
}

.elements-wrapper {
  display: flex;
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 20px 0;
    border: 1px solid $border-color;
  }

}

.element,
.table-element {
  cursor: pointer;
  background-color: $dark-gray;
}

.canvas-element {
  cursor: pointer;
  width: 100%;
  height: 100%;

  .icon {
    height: 20px;
    width: auto;
  }

  .title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: rem(16px);
    text-align: center;
  }

  &.size-S {

    .title,
    .table-capacity {
      font-size: rem(12px);
    }

  }

  &.size-L {

    .title,
    .table-capacity {
      font-size: rem(18px);
    }

  }

}

.table-element,
.canvas-element.bordered-medium,
.canvas-element.bordered-light {
  display: flex;
  align-items: center;
  justify-content: center;
}

.canvas-element.bordered-medium.active,
.canvas-element.active .table-element,
.canvas-element.bordered-light.active {
  background-color: $accent-color;
  color: $white;
  border-color: $accent-color;

  .resize-icon {
    display: block;
  }
}

.table-element {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  padding: 4px 6px;
  color: $white;

  &.not-bookable {
    opacity: 0.6;
  }

}

.table-capacity {
  display: flex;
  justify-content: space-around;
  width: 100%;
  text-align: center;

  strong {
    margin: 0 2px;
  }

}

.element-square,
.element-rectangle {
  border-radius: 8px;
}

.element-circle {
  border-radius: 50%;
}

.bordered-light,
.bordered-medium {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: rem(14px);
  border-width: 1px;
  border-style: solid;
  text-align: center;
  color: $primary-color;

  .icon {
    display: block;
    margin: 0 auto 5px;
    color: inherit;
  }

  &.not-bookable {
    opacity: 1;
  }

}

.bordered-light {
  border-color: $light-gray;
  background-color: $white;

  .table-capacity {
    border-color: $light-gray;
  }

}

.bordered-medium {
  border-color: $dark-gray;
  background-color: $gray;

  .table-capacity {
    border-color: $dark-gray;
  }

}

.details-form {
  padding: 20px;
  border-top: 1px solid $border-color;

  .form-group {

    label {
      position: relative;
      z-index: 2;
      margin-bottom: -5px;
      font-size: rem(12px);
    }

    input {
      padding-top: 10px;
    }

  }

}

.delete-element {
  @include reset-btn;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $error-color;
  text-transform: uppercase;
  text-decoration: underline;

  .icon {
    color: inherit;
    width: 30px;
  }

}

.resize-icon {
  display: none;
  position: absolute;
  top: -18px;
  right: -16px;
  width: 40px;
  height: auto;
}

.table-sizes {
  display: flex;
  width: 100%;
  height: 45px;
  margin-bottom: 20px;
  background-color: $gray;
  border-radius: 16px;

  button {
    @include reset-btn;

    height: 100%;
    width: 100%;
    font-weight: bold;
    font-size: rem(24px);
    border-radius: 16px;

    &.active {
      background-color: $accent-color;
      color: $white;
    }

  }

}

.react-draggable {
  z-index: 2;
}

.lower-z {
  z-index: 1;
}

.active-wrapper {
  z-index: 3;
}

.zoom-in,
.zoom-out {
  @include reset-btn;

  width: 25px;
  height: 25px;
  margin-left: 5px;
  padding: 3px;
  border: 1px solid $border-color;

  &[disabled] {
    cursor: not-allowed;
    background-color: $gray;
  }

}

@include large {

  .btn-discard {
    margin-left: 0;
    margin-right: 30px;
    font-size: rem(18px);
  }

}
