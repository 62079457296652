.offer-type-block {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: $box-shadow;
  border-radius: 16px;

  h3 {
    margin: 0;
    font-size: rem(18px);
  }

  .btn {
    display: table;
    margin: 0;
    background-color: $white;
  }

  .icon {
    position: absolute;
    z-index: -1;
    top: 10%;
    right: 10px;
    height: 80%;
    width: auto;
  }

}

@include large {

  .offer-type-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
  }

}
