.email-container {
  margin-top: 30px;
}

.add-logo-btn {
  display: block;
  height: 62px;
  max-width: 360px;
  padding: 20px;
  border: 1px solid $border-color;
  cursor: pointer;
}

.email-logo,
.add-logo-btn {
  margin: 0 auto;
}

.email-logo {
  display: table;
  position: relative;

  img {
    height: 62px;
    max-width: 360px;
  }

  .edit-img {
    position: absolute;
    left: 100%;
    top: 0;
    padding: 10px;
    cursor: pointer;
  }

}
